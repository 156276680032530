@media screen and (max-width: 1778px) {
  .taskviewtaskfilter {
    overflow: auto;
    min-width: 700px;
  }

  .suggestfilteroverfolw {
    overflow: auto;
  }

  .suggestinputtupeforgrey {
    min-width: 1190px;
    overflow: auto;
  }

  .filterinputscontactspage {
    width: 50%;
  }



  .filterinputssuggestpage {
    min-width: 794px;
    overflow: auto;
  }
}

@media screen and (max-width: 1650px) {
  .taskviewwidthfilter {
    /* min-width: 1500px; */
    /* min-width: 976px; */
    min-width: 1100px;
    /* overflow: auto; */
  }

  .taskviewwidthfilter2 {
    min-width: 1100px;
  }

  .usersectionfilter {
    width: 51%;
  }

  .filterinputscontactschoose {
    width: 33%;
  }

  .suggestcolmn {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .maintableborder {
    overflow: auto;
  }
}



@media screen and (max-width: 1600px) {

  .filterinputscontactschoose {
    width: 36%;

  }


  .filterinputscontactspage {
    width: 60%;
  }



  .quickview {
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
  }
}

@media screen and (max-width: 1560px) {


  .buttonviewreports {
    font-size: 0.8rem;
    margin-left: 2rem;
    padding: 0rem;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .createForFixWidthMobile {
    right: 28% !important;
  }

  .search-section {
    width: 27%;
  }

  .marketingopenmailers {
    gap: 2rem !important;
  }

  .comparisontabledata {
    gap: 0rem;
  }

  /* .buttonviewreports {
    width: 100%;
    padding: 0px;
    margin-left: 1rem;
  } */
  .primaryowner {
    margin: 1rem;
  }

  .custom-calendar-inputs {
    width: unset !important;
  }
}

@media screen and (max-width: 1438px) {
  .taskviewwidthfilter {
    min-width: 1015px;
  }

  .taskviewwidthfilter2 {
    min-width: 860px;
  }
}

@media screen and (max-width: 1250px) {
  .taskviewwidthfilter {
    min-width: 890px;
    max-width: 950px;
  }
}

@media screen and (max-width: 1100px) {
  .taskviewwidthfilter {
    min-width: 795px;
    max-width: 900px;
  }
}

@media screen and (max-width: 700px) {
  .taskviewtaskfilter {
    overflow: auto;
    max-width: 700px;
    min-width: 450px;
  }

  .taskviewwidthfilter {
    min-width: 1300px;
  }

  .taskviewwidthfilter2 {
    min-width: 1300px;
  }

}


@media screen and (max-width: 500px) {
  .taskviewtaskfilter {
    overflow: auto;
    max-width: 500px;
    min-width: 450px;
  }

}

@media screen and (max-width: 450px) {
  .taskviewtaskfilter {
    overflow: auto;
    max-width: 500px;
    min-width: 400px;
  }

}

@media screen and (max-width: 390px) {
  .taskviewtaskfilter {
    overflow: auto;
    max-width: 500px;
    min-width: 300px;
  }

}



@media screen and (max-width: 1500px) {

  .sideselectedproperties {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .propertyoverviewformobileandlaptop {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1450px) {
  .minHeightTableHome {
    min-height: 0px;
  }
}

@media screen and (max-width: 1400px) {
  .sideselectedproperties {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .forcolspan {
    grid-column: span 2 / span 2 !important;
  }

  .suggestcolmn {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }




  .marketingopenmailers {
    gap: 5rem !important;
  }

  .comparisontabledataresponsivescrollbar {
    overflow: auto;
  }

  .comparisontabledata {
    overflow: auto;
    min-width: 1200px;
  }

  /* .filterinputscontentteamreports {
    overflow-x: scroll;
    width: 100%;
  } */

  .contactpreviewsimpleaccordian {
    overflow: auto;
  }

  .contactpreviewscrollbar {
    overflow: auto;
    /* min-width: 1400px; */
  }
}


@media screen and (max-width: 1702px) {
  .transactionactivecloumn {
    grid-template-columns: repeat(3, minmax(0, 1fr));

  }

  .analyzetitleandcontentstyle {
    gap: 3rem;
  }

  .addclosingcolumndata {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }



  .filteranalyzedatableinput {
    width: 46% !important;
  }

  .marketingopenmailers {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .templatesmarketing {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .maindivperformance {
    overflow: auto;
  }

  .performancescrollcanvas {
    overflow: auto;
    min-width: 1300px;
  }

  .filterinputscontentaddress {
    display: unset;
    justify-content: unset;
    align-items: unset;
    width: unset;
  }



  .filterinputscontactspage {
    width: 70%;
    overflow-x: auto;
  }

  .filterinputscontents {
    width: 100%;
  }



  .paginationsubdata h3 {
    color: #70747b;
    font-size: 0.8rem;
  }

  .primaryowner h4 {
    font-size: 14px;
  }


}

@media screen and (max-width: 1800px) {
  .filterinputscontactspage {
    width: 66%;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1700px) {
  .filterinputscontactspage {
    width: 70%;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1600px) {
  .filterinputscontactspage {
    width: 85%;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1500px) {
  .filterinputscontactspage {
    width: 95%;
    overflow-x: auto;
  }
  .performancescrollcanvas {
    overflow: auto;
    min-width: 1000px;
  }
}

@media screen and (max-width: 1367px) {
  .transactionactivecloumn {
    grid-template-columns: repeat(2, minmax(0, 1fr));

  }

  .analyzetitleandcontentstyle {
    gap: 3rem;
  }

  .addclosingcolumndata {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }



  .filteranalyzedatableinput {
    width: 46% !important;
  }

  .marketingopenmailers {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .templatesmarketing {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .maindivperformance {
    overflow: auto;
  }

  .performancescrollcanvas {
    overflow: auto;
    min-width: 890px;
  }

  .filterinputscontentaddress {
    display: unset;
    justify-content: unset;
    align-items: unset;
    width: unset;
  }


  /* it is changed from 70% to 100% */
  .filterinputscontactspage {
    width: 100%;
    overflow-x: auto;
  }

  .filterinputscontents {
    width: 100%;
  }



  .paginationsubdata h3 {
    color: #70747b;
    font-size: 0.8rem;
  }

  .primaryowner h4 {
    font-size: 14px;
  }


}

@media screen and (max-width: 1199px) {
  .onlycontactmodalformaxwidth {
    width: 600px !important;
  }



  .addclosingcolumndata {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .filterinputscontactspage {
    width: 100%;
  }

  .filteranalyzedatableinput {
    width: 100% !important;
    overflow-x: scroll;
  }

  .analyzetitleandcontentstyle {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .marketingopenmailers {
    gap: 2rem !important;
  }

  .MuiBox-root .css-sq6ulk {
    right: 14%;
  }

  /* .table-theme-1 td {
    min-width: 200px;
  } */

  .filterinputscontentlistening {
    width: 100%;
    overflow-x: scroll;
  }

  .filterinputscontentlistening {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}


@media screen and (max-width: 1077px) {
  .usersectionfilter {
    width: 100%;
    overflow: auto;
  }

  .transactionactivecloumn {
    grid-template-columns: repeat(2, minmax(0, 1fr));

  }

  .marketingopenmailers {
    gap: 5rem !important;
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

@media screen and (max-width: 1520px) {
  .MuiTabs-scroller {
    overflow-x: auto !important;
  }
}


@media screen and (max-width: 700px) {
  .transactionactivecloumn {
    grid-template-columns: repeat(1, minmax(0, 1fr));

  }

  .formobilesearchiconandbutton {
    display: block;
    /* padding-top: 2rem; */
  }

}

@media screen and (max-width: 991px) {
  .ForFixWidthMobile {

    width: 300px !important;
  }

  .calendertopwidth {
    top: 64px !important;
  }

  .onlyemailtemllate {
    height: 50vh;
  }

  .filterinputscontactspage {
    width: 100%;
  }

  .formobiletopalign {
    top: 64px !important;
  }

  .marketingopenmailers {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .MuiTabs-scroller {
    overflow-x: auto !important;
  }

 
  .sidemobile {
    z-index: 100000 !important;
    height: 100vh;
    width: 100%;
    background: #eaeeff 0% 0% no-repeat padding-box;
  }

  .page-container {
    width: 100%;
  }

  .page-container2 {
    width: 100%;
  }

  .Layout {
    display: block;
  }

  .side-nav {
    display: none;
  }

  .side-nav2 {
    display: none;
  }


  .maincontactoverview {
    font-size: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .typeselect select {
    width: 21%;
  }
}

@media screen and (max-width: 768px) {
  .sequencesummary {
    top: 55% !important;
  }
  .ForFixWidthMobile {

    width: 601px !important;
  }
  .eventsdatesformobile {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .calendereventsdate {
    width: 244px;
  }

  .filedropzonestyle p span {
    font-size: 0.8rem;

  }

  .noteaddmodal .rdw-editor-main {
    min-height: 286px !important;
  }

  .forcolspan {
    grid-column: span 1 / span 1 !important;
  }

  .suggestcolmn {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .radioBtnEventName p {
    font-size: 10px;
  }



  .createsequencedata {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sliderformobileheight {
    min-height: unset !important;
  }

  .onlycontactmodalformaxwidth {
    width: 350px !important;
  }





  .allmodalwidthandscroll {
    overflow: auto;
    /* min-width: 600px; */

  }

  .onlyemailtemplateswidthscroll {
    overflow: auto;
    min-width: 800px;
  }

  .maindivconetnt {
    padding-bottom: 3rem;
    height: 80vh;
    width: 500px;
    overflow: auto;
  }

  .generalsettingsequences {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .marketingopenmailers {
    gap: 2rem !important;
  }

  .search-sections {
    width: unset;
  }

  .primaryowner {
    margin: 1rem;
  }

  .primaryowner {
    margin-left: 0rem;
  }

  .primaryowner select {
    min-width: 182px;
  }

  .addtaskbutton {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .maincontact {
    padding-top: 1rem;
  }

  .main-wraper {
    margin: auto;

    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 1rem;
    padding-bottom: 1.5rem;
    background-color: #fff;
  }

  .search-sections .searchicon {
    top: 31%;
  }

  /* .contactoverview {
          display: block;
        } */

  .inputtypesearch {
    font-size: 0.6rem;
  }

  .page-container {
    width: unset;
    height: unset;
    overflow-y: unset;
  }

  .page-container2 {
    width: unset;
    height: unset;
    overflow-y: unset;
  }

  .header {
    padding: 19px 105px;
  }
}

@media screen and (max-width: 599px) {
  .forcolspan {
    grid-column: span 1 / span 1 !important;
  }
  .ForFixWidthMobile {

    width: 370px !important;
  }
  .suggestcolmn {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sideselectedproperties {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .addclosingcolumndata {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .taskdetailmaindivmodal .css-1ipemus {
    width: 375 !important;
  }

  .propertyoverviewlistingdateseller {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .marketingopenmailers {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

@media screen and (max-width: 414px) {
  .sequencesummary {
    top: 57% !important;
  }

  .filterinputscontactschoose {
    width: 100%;
    overflow: auto;
  }

  .reportdateforclosing {
    width: 358px !important;
  }

  /* .noteaddmodal .rdw-editor-main{
    min-height: 230px !important;
  } */
  .transactionactivecloumn {
    grid-template-columns: repeat(1, minmax(0, 1fr));

  }

  .proprtyimagesformobile {
    display: block;
  }

  .featurestextareaformobile {
    display: block;
  }

  .custom-calendar-inputlissting {
    width: 166%;
  }

  .buttonsonlymarketing {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .sequensebuttoninycreate {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .onlyemailtemplateswidthscroll {
    overflow: auto;
    min-width: 1000px;
  }

  .addclosingcolumndata {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .buttonforaddfoldermobile {
    padding-top: 1rem;
  }

  .viewtemplatesformobile {
    display: block;
  }

  .contactsearchiconofrext {
    padding-right: 0px;
  }

  .analyzetitleandcontentstyle {
    gap: 1rem;
  }

  .search-sectionsbgcolor .searchicon {
    left: 10px;
  }

  .search-sectionsbgcolor {
    padding-left: 31px;
  }

  .propertyoverviewlistingdateseller {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .buttonsequence {
    padding: 7px;
  }

  .marketingopenmailers {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .goalreportstable {
    padding-right: 2rem;
  }

  .paginationsubdata h3 {
    padding-left: 1rem;
  }

  .paginationtextandcontent {
    display: unset;
  }

  .statusselect select {
    min-width: 200px;
  }

  .search-section .searchicon {
    top: 18%;
  }
}

@media screen and (max-width:360px) {
  .noteaddmodal .rdw-editor-main {
    min-height: 257px !important;
  }

  .buttons {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

}

@media screen and (max-width:375px) {
  .sequencesummary {
    top: 66% !important;
  }

  .reportdateforclosing {
    width: 322px !important;
  }

  .radioBtnEventName p {
    font-size: 11px;
  }

  .custom-calendar-inputlissting {
    width: 148%;
  }

}