@font-face {
  font-family: 'GillSansLight';
  font-weight: normal;
  font-display: swap;
  src: url(./MonotypeGillSansNovaLight.woff2) format('woff2');
}

@font-face {
  font-family: 'GillSansMedium';
  font-weight: 500;
  font-display: swap;
  src: url(./MonotypeGillSansNovaMedium.woff2) format('woff2');
}
