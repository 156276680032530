@media (min-resolution: 120dpi) and (max-resolution: 130dpi) and (min-width: 1400px) and (max-width: 1500px) {

    /* .newSideheaderMain {
      zoom: 100%;
    } */
    .header,
    .mainWrapper,
    .side-nav, .side-nav2 {
        zoom: 78%;
    }

    .page-container {
        width: 100% !important;
    }

    /* .fullHeightLogin{
        zoom: 85%;
    }
    .fullHeightLogin .d-flex .align-items-center .justify-content-center .row{
        zoom: 100% !important;
    } */
}