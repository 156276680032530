@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("../font/font.css");
@import url("./dpi.css");
/* @font-face {
  font-family: "GillSansMedium";
  font-weight: 500;
  font-display: swap;
  src: local('GillSansLight') url("https://www.brokeriq.virani.ca/fonts/MonotypeGillSansNovaMedium.ttf") format("truetype");
} */

#yourInputId {
  /* Styles for the element with the specified id */
  border: 2px solid #007bff;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

/* @font-face {
  font-family: "Justusroman";

  font-weight: 500;
  font-display: swap;
  src: local(Justusroman),
    url("https://www.brokeriq.virani.ca/fonts/Justus-Roman.ttf");


} */

/* @font-face {
  font-family: "GillSansLight";
  font-weight: normal;
  font-display: swap;
  src: local("GillSansLight"),
    url("https://www.brokeriq.virani.ca/MonotypeGillSansNovaLight.ttf") format("truetype");


}


@font-face {
  font-family: "poppinsregular";
  font-display: swap;
  src: local("poppinsregular");
  src: url("https://www.brokeriq.virani.ca/public/fonts/Poppins-Regular.ttf") format("truetype");
} */
@font-face {
  font-family: 'MonotypeGillSansNovaLight';
  font-weight: 500;
  font-display: swap;
  src: url(https://www.brokeriq.virani.ca/font/MonotypeGillSansNovaLight.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins-Regular';
  font-weight: 500;
  font-display: swap;
  src: url(https://www.brokeriq.virani.ca/font/Poppins-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'MonotypeGillSansNovaMedium';
  font-weight: 500;
  font-display: swap;
  src: url(https://www.brokeriq.virani.ca/font/MonotypeGillSansNovaMedium+%281%29.woff2) format('woff2');
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}



ul {
  list-style: none;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
span,
p,
h6,
button {
  font-family: "GillSansMedium", sans-serif;
}



body {
  font-family: "GillSansLight", sans-serif;
  font-size: 14px;
  position: relative;
}

.side-nav {
  width: 250px;
  background: #eaeeff 0% 0% no-repeat padding-box;

}

.side-nav2 {
  width: 100px;
  background: #eaeeff 0% 0% no-repeat padding-box;

}

.sidebarhead {
  margin-top: 50px;
}

.sidebarhead h3 {
  margin-left: 35px;
}

.page-container {
  width: calc(100% - 250px);
  height: 100vh;
  overflow-y: scroll;
}

.subheader_design {
  font-family: "GillSansMedium";
  /* text-transform: capitalize; */
  color: #70747b !important;
  font-size: 0.9rem;
  /* width: 100%; */
  padding-top: 2px;
  margin-left: 2px;
  /* min-width: 151px; */
}

.page-container2 {
  width: calc(100% - 100px);
  height: 100vh;
  overflow-y: scroll;
}

.overflowyhidden {
  overflow-y: hidden !important;
}


.flex_imp {
  display: flex !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  min-height: 38px;
}

.text_18pximp {
  font-size: 18px !important;
}

.notification-icon-container {
  position: relative;
  display: inline-block;
}

.settiniconshoudlhover {
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}

.small_container_desing {
  display: flex;
  min-width: 0;
  background-color: #eaeeff;
  border-radius: 2px;
  margin: 2px;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 3px;

}

.ellipse_cutter_desing {
  max-width: 16ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.datepickerdesing .react-datepicker__close-icon {
  margin-right: 13%;
}

.logemailmultiselect .css-1r4vtzz {
  background-color: #f5f5f5;
}

.logemailmultiselect .css-48ayfv {
  background-color: #f5f5f5;
}

.settiniconshoudlhover:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.notificationiconhover {
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.notificationiconhover:hover {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}

.notification-number {
  display: flex;
  position: absolute;
  top: -10px;
  right: 6px;
  background-color: #667DD1;
  color: white;
  font-size: 12px;
  /* padding: 8px 8px; */
  /* padding: 5px; */
  padding-top: 2px;
  width: 25px;
  height: 25px;
  justify-content: center;
  border-radius: 50%;
}

.paddingdesingmastetab {
  padding-top: 1rem;
  padding-bottom: 1rem;
}


.margin_top_15px_imp {
  margin-top: 15px !important;
}

.width_height_30px_imp {
  width: 30px !important;
  height: 30px !important;
}

.rbc-current-time-indicator {
  background-color: #667DD1 !important;
}

.overflow_scroll_Important {
  overflow: scroll !important;
}


.contactoverviewcontactmethod .react-tel-input .flag-dropdown {
  display: none;
}

.contactoverviewcontactmethod .react-tel-input {
  margin-left: -41px;
}


.max-hight {
  width: 162px;
}

.max-hight img {
  width: 100%;
  height: 100%;
}


.common_loader_desing {
  border: 5px solid #8796d2;
  border-top: 5px solid #eaeeff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: loader 1s linear infinite;
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  transform: translate(-50%, -50%);

}

.justify_normal_imp {
  justify-content: normal !important;
}

.reddesingdesing {
  font-size: 0.9rem !important;
  color: #f0142f !important;
}

.side-menu li a {
  cursor: pointer;
  padding: 8px 20px;
  font-size: 18px;
  color: #8892a6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 20px;
}

.Layout {
  display: flex;
}


.resend_desing {
  font-family: "GillSansMedium";
  border-radius: 15px;
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important;
  padding: 4px;
  color: #667DD1;
  font-size: 0.9rem;
  background-color: #d3daf8;
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  border: 1px solid #d3daf8;
  min-width: 92px;
}

.side-menu li ul {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease;
}

.todayending_bottom_red5 {
  border-bottom: 1.5px solid #667DD1 !important;
}


.unselecteddesign {
  text-transform: uppercase;

  font-family: "GillSansMedium";
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important;
  padding: 4px;
  border: 1px solid #667DD1;
  border-radius: 15px;
  background-color: #fff;
  color: #667DD1;
  font-size: 0.7rem;

}

.selecteddesing {
  text-transform: uppercase;
  font-family: "GillSansMedium";
  border-radius: 15px;
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important;
  padding: 4px;
  color: #667DD1;
  font-size: 0.7rem;
  background-color: #d3daf8;
  border: 1px solid #d3daf8;
}

.submenu {
  max-height: 500px !important;
}

.downArrow {
  width: 12px;
  transform: rotate(0deg);
  transition: transform 0.5s ease;
}

.upArrow {
  transform: rotate(180deg);
}


.w_5remimp {
  width: 5rem !important;
}

.l_16pximp {
  left: 16px !important;
}

.phonecallglobaldesing {
  padding: 1.5rem;
  height: calc(100vh - (16vh + 197.36px));
  overflow: auto;
  padding-top: 0rem !important;
}

.phonecallglobaldesingsecond {
  padding: 1.5rem;
  height: calc(100vh - (3vh + 197.36px));
  overflow: auto;
  padding-top: 0rem !important;
}

.line_clamp_50pxhwI {
  min-height: 50px !important;
  /* overflow: scroll; */
  max-height: 50px !important;
}

/* .css-1fdsijx-ValueContainer {
  cursor: pointer;
} */

.side-menu li a {
  font-size: 1rem;
  color: #667DD1;

  font-weight: 400;
  transition: font-weight 0.3s ease-in-out;
}

.teammemberheading .css-48ayfv {
  width: 99.5% !important;
  min-height: 39px;
  box-shadow: none !important;
}

.teammemberheading .css-1r4vtzz {
  width: 99.5% !important;
  min-height: 39px;
  box-shadow: none !important;
}

.teammemberheading .css-1r4vtzz .css-1pcexqc-container .css-clsh64-control:hover {
  color: #fff !important;
}

.css-clsh64-control:hover {
  color: #fff !important;
  background-color: #6A7ABC;
}

.css-1wpf0s6:hover {
  color: #fff !important;
}

.teammemberheading .css-48ayfv:hover input {
  color: white !important;
}

.posnonimp {
  position: none !important;
}

/* .side-menu li a:hover {
  font-family: "GillSansMedium";

  color: #ffff;
} */

.left_m_22 {
  left: -22px !important;
}

.side-menu li a.active {
  color: #ffff;
  font-family: GillSansMedium;
}

.side-menu li ul a.actives {
  color: #3f0a87;
}

.react-phone-number-input__icon>svg {
  fill: black !important;
  /* Change the fill color to black */
}

.PhoneInputCountry {
  background-size: 16px 16px;
  /* Adjust icon size as needed */
  background-repeat: no-repeat;
  background-position: right center;
  padding-left: 10px;
  /* Add space for the icon */
  /* border: 1px solid #ccc; */
  border-radius: 4px;

  font-size: 16px;



}


.inviteuser2 .PhoneInputCountry {
  display: flex;

  flex-direction: row-reverse;
}

.inviteuser2 .PhoneInputCountry .select {
  width: 20px;
}


.inviteuser2 .PhoneInputCountrySelect {
  width: 16px;
  background: #fff;
  cursor: pointer;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {

  transform: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}

.imp_p_t {
  padding-top: 0 !important;
}

.meeting .public-DraftStyleDefault-block {
  background: none;
}

.meeting .public-DraftStyleDefault-atomic {
  background: none;
}

.emailmeetingbodyarea .public-DraftStyleDefault-block {
  background: none;
}

.emailmeetingbodyarea .rdw-editor-main {
  height: 91%;
}

.emailmeetingbodyarea .public-DraftEditor-content[contenteditable=true] {
  height: 118%;
}

.public-DraftStyleDefault-block {
  /* margin: 1em 0; */
  margin: none !important;
}

.emailmeetingbodyarea .public-DraftStyleDefault-atomic {
  background: none;
}

.contacttableborderSuccess {
  border-left: 3px solid #F0142F;
  width: 2.5px;
  height: 2.5rem;
  top: 50%;
  transform: translate(-0%, -50%);
  position: absolute;
  left: 0;
}

.contacttablebordecircledotb {
  border-left: 3px solid #F47B21;
  width: 2.5px;
  height: 2.5rem;
  top: 50%;
  transform: translate(-0%, -50%);
  position: absolute;
  left: 0;
}

#printDetails {
  display: none;
  visibility: hidden;
}

.gap2p {
  gap: 3% !important;
}

.gap05 {
  gap: 0.5rem;
}

.height_auto_important {
  height: auto !important;
}

.notificationbirthday .css-i4bv87-MuiSvgIcon-root {
  margin-top: 0px !important;
}


.ml-0 {
  margin-left: 0 !important;
}

.universalcolorimportant {
  color: #667DD1 !important;
}

.contacttablebordecircledotc {
  border-left: 3px solid #6A7ABC;
  width: 2.5px;
  height: 2.5rem;
  top: 50%;
  transform: translate(-0%, -50%);
  position: absolute;
  left: 0;
}

.contacttablebordecircledot {
  border-left: 3px solid #668cf6;
  width: 2.5px;
  height: 2.5rem;
  top: 50%;
  transform: translate(-0%, -50%);
  position: absolute;
  left: 0;
}


.divbackgroundimage {
  background: #e5e7eb;
  border-radius: 50%;
}

.impcolor {
  color: #667DD1 !important;
}

.circledot {
  width: 0.6rem;
  margin-right: 0.5rem;
  height: 0.6rem;
  background-color: #668cf6;
  border-radius: 50%;
}

.circledota {
  width: 0.6rem;
  margin-right: 0.5rem;
  height: 0.6rem;
  background-color: #F0142F;
  border-radius: 50%;
}

.circledotb {
  width: 0.6rem;
  margin-right: 0.5rem;
  height: 0.6rem;
  background-color: #F47B21;
  border-radius: 50%;
}

.txtttt:hover {
  text-decoration: underline !important;
}

.taskprimeheading {
  text-transform: uppercase;
  font-family: "GillSansMedium";
  color: #667DD1;
  margin-top: -15px;
  margin-bottom: 16px;
  font-size: 1.5rem;
}

.infoiconglobalsettlement {
  margin-left: 0.2rem !important;
  padding-bottom: -2px !important;
  margin-top: -3px !important;
}

.viewemailtemplatehbodydesing {
  /* overflow: auto; */
  color: #70747b !important;
  font-family: "GillSansMedium";
  font-size: 1rem;
  max-height: 20vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.viewemailtemplatehbodydesing2nd {
  overflow: auto;
  color: #70747b !important;
  font-family: "GillSansMedium";
  font-size: 1rem;
  min-height: calc(100vh - (42vh + 197.36px));
  max-height: calc(100vh - (42vh + 197.36px));
}

.viewemailtemplatehbodydesing3nd {
  overflow: auto;
  color: #70747b !important;
  font-family: "GillSansMedium";
  font-size: 1rem;
  min-height: calc(100vh - (46vh + 197.36px));
  max-height: calc(100vh - (46vh + 197.36px));
}

.circledotc {
  width: 0.6rem;
  margin-right: 0.5rem;
  height: 0.6rem;
  background-color: #6A7ABC;
  border-radius: 50%;
}

.active {
  /* color: #3f0a87; */
  font-family: GillSansMedium;
  /* padding-left: 20px !important; */
  background: #667DD1 0% 0% no-repeat padding-box;
  border-radius: 3px;
  transition: transform 0.5s ease;
}


.colorwhite {
  color: white !important;
}

.tabposition {
  position: absolute;
  top: -70px;
  right: 0;
}

.react-tel-input .country-list .country {
  padding: 7px 9px;
  background: none !important;
}

.header {
  background: #fff;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.reporstsspacefortab {
  margin-right: 20px !important;
}

.pipelinecanvas {
  margin-left: 15px !important;
}

.CustomSelectStyle {
  font-family: "GillSansMedium";
  color: #70747b;
  font-size: 14px;
  width: 200px;
}


.CustomSelectStylewithoutwidth {
  font-family: "GillSansMedium";
  color: #70747b;
  font-size: 14px;
  /* width: 200px; */
}
.CustomSelectStyleofselectinmaster {
  font-family: "GillSansMedium";
  color: #70747b;
  font-size: 14px;
  width: 152px;

}


.bgdesinghomepagesome {
  background-color: #E7EBFB;
  color: #131523 !important;
  border: 1px solid #E7EBFB !important;
  font-family: "GillSansMedium";
  border-radius: 14px;
  padding: 3px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px !important;
  margin-top: 1rem;
}


.mb1pi {
  margin-bottom: 1% !important;
}

.br-10pxi {
  border-radius: 10px !important;
}

.pipelinescrollcanva .MuiButtonBase-root {
  align-items: baseline !important;
  min-width: 35px !important;
}

.CustomSelectStyles {
  font-family: "GillSansMedium";
  color: #09090a;
  font-size: 14px;
}

.CustomSelectStyleofselectinmaster {
  font-family: "GillSansMedium";
  color: #09090a;
  font-size: 14px;
}

.mt-0i {
  margin-top: 0 !important;
}



.renametemplateinputt:focus,
.renametemplateinputt:active {
  border: 1px solid #667DD1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.renametemplateinputt:-webkit-autofill,
.renametemplateinputt:-webkit-autofill:hover,
.renametemplateinputt:-webkit-autofill:focus,
.renametemplateinputt:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}


.viewtemplatesmargin {
  margin-left: 13px !important;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.editDeleteModal {
  background-color: #ffffff;
  padding: 1rem;
  padding-right: 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 5px -2px rgb(0 0 0 / 20%) !important;
  position: absolute;
  right: 0;
  z-index: 99;
}

.main-wraper {
  background-color: #fff;
  padding: 40px;
}

.menuhead {
  padding-top: 21px;
  padding-bottom: 0px;
  padding-right: 50px;
  padding-left: 50px;
}

.menuhead2 {
  padding-top: 42px;
  width: 88%;
  padding-left: 2%;
}

.header {
  padding: 17px 50px;
}

.avatar {
  font-size: 0.8rem !important;
  background: #f5f6fa 0% 0% no-repeat padding-box !important;
  letter-spacing: 0.07px;
  color: #131523 !important;
}

.vertical-line {
  width: 2px;
  height: 45px;
  background-color: #f5f6fa;
}

.search-section {
  width: 22%;
  position: relative;
  margin-right: 1rem;
}

.search-sectionsbgcolor {
  position: relative;
}

.search-sectionsbgcolor {
  border-radius: 46px;
  font-family: "GillSansMedium";
  font-size: 1rem;
  color: #70747b;
  /* background-color: #f5f5f5; */
  /* border-bottom: 1.5px solid #e6e6e9; */
  padding-left: 31px;
}

.search-sectionsbgcolor :focus-visible {
  border: 1px solid #667DD1 !important;
  outline: 1px solid #667DD1 !important;
}

.search-sections {
  width: 100%;
  position: relative;
}

.homeheadinglinesblue {
  width: 100%;
}

.search-section input {
  font-family: "GillSansMedium";
  font-size: 1rem;
  color: #70747b;
  padding-left: 30px;
}

.search-sections input {
  font-family: "GillSansMedium";
  font-size: 1rem;
  color: #131523;
  /* border-bottom: 1.5px solid #e6e6e9; */
  padding-left: 59px;
  background: #f5f5f5;
  padding-top: 5px;
  padding-bottom: 5px;
}

.search-section .searchicon {
  position: absolute;
  /* left: 10px; */
}

.search-sections .searchicon {
  position: absolute;
  left: 2rem;
}

.search-sectionsbgcolor .searchicon {
  position: absolute;
  left: 40px;
}

.search-sectionsbgcolor:focus {
  border: 0.5px solid #667DD1 !important;
}

.search-section input:focus {
  outline: none !important;
  outline: none;
  width: 100% !important;
  border-bottom: 1px solid #e6e6e9;
}

.search-sections input:focus {
  outline: none;
  /* border-bottom: 0.5px solid #667DD1; */
}

.moreIcon {
  display: flex;
  /* justify-content: center;
  align-items: flex-start; */
}

.hoverdeleteiconbg {
  background-color: #fde4e7;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editIconMain {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  cursor: pointer;
}

.editdeleteiconbg {
  background-color: #ffedde;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 
.editIconMain:hover {
  background-color: #f7f8fb;
} */

.edithovericonbg {
  background-color: #f5f5f5;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.w_h_08impp {
  width: 0.8em !important;
  height: 0.8em !important;
}




.PT-i0 {
  padding-top: 0% !important
}

.termandconditionparetntwrapper {
  font-size: 1.5em !important;
  line-height: 1.6 !important;
  font-weight: 400 !important;
  font-family: Montserrat !important;
  color: #222222 !important;

}

.w93pcimp {
  width: 93% !important;
}

.topbuttonassigner {
  cursor: pointer;
  padding-top: 7%;
  color: #6A7ABC;
  border-bottom: 1px solid #6A7ABC;
  font-size: 12px;
  margin-bottom: 5px;
}

.topbuttonassigner2nd {

  cursor: pointer;
  width: fit-content;
  padding-top: 7%;
  color: #6A7ABC;
  border-bottom: 1px solid #6A7ABC;
  font-size: 14px;
  margin-bottom: 5px;

}

.editIconMain h3 {
  font-size: 0.8rem;
  color: #131523;
  margin-bottom: 0;
  margin-left: 1rem;
}

.editIconMain:hover {
  background-color: #f7fafb;
}



.moreIcon h3 {
  color: #70747b;
  font-size: 0.9rem;
  padding-right: 1rem;
}

.activeheadingsmodal {
  text-transform: uppercase !important;
  background-color: none !important;
  font-weight: 500;
  color: #70747b !important;
  font-size: 1rem !important;
  font-family: "GillSansMedium" !important;

  line-height: 0;
  letter-spacing: 0;
  min-width: 0;
  background-color: none !important;
  text-shadow: none !important;
  box-shadow: none !important;
  padding: 0px 0px;
}

.contactsummarydetailmodal {
  margin-right: 13px !important;
}

.MuiButtonBase-root {
  padding: 12px 0px !important;
}

/* .MuiButtonBase-root{
  padding: 0px 0px !important;
  background-color: none !important;
  font-weight: 500;
  color: #70747b !important;
  font-size: 1rem !important;
  font-family: "GillSansLight" !important;
  text-transform: none !important;
  line-height: 0;
  letter-spacing: 0;
  min-width: 0;
  background-color: none !important;
  text-shadow: none !important;
  box-shadow: none !important;
} */

.contactpropertiesmain .MuiTabs-indicator {
  background-color: unset !important;
}

.MuiTabs-indicator {
  background-color: #667DD1 !important;
}

.Mui-selected {
  background-color: none !important;
  color: #131523 !important;
  font-size: 1rem !important;
  font-family: "GillSansMedium" !important;
  text-transform: none;
  line-height: 0;
  letter-spacing: 0;
  min-width: 0;
  box-shadow: none !important;
  text-shadow: none !important;
  background-color: none !important;
  text-transform: uppercase !important;
  padding: 3px 4px;
}

.addtaskmodal div span {
  visibility: hidden;
  background-color: none !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
  /* margin-top: 1rem; */
  margin-bottom: 1rem;
}

.table-theme-1 th {
  font-weight: 500;
  font-family: "GillSansMedium";
  position: sticky;
  top: 0px;
  background-color: #f5f5f5 !important;
  color: #131523 !important;

  padding: 0.5rem;
  font-size: 0.9rem;

  text-align: left;
  padding-left: 2rem;
}

.table-theme-1 tr {
  border-bottom: 1px solid #e6e6e9;
}

.maintablebor {
  border-top: none !important;
  overflow: auto;
  border: 1px solid #e6e6e9;
  border-radius: 5px;
}

.maintableborder {
  /* overflow: auto; */
  border: 1px solid #e6e6e9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.minHeightTableHome {
  min-height: 410px;
}

.table-theme-1 td .sortnamedata {
  background-color: #f5f5f5;
  min-width: 2rem !important;
  min-height: 2rem !important;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-family: "GillSansMedium";
  color: #52575d;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}


/* div connecting to each other */


.comment {
  position: relative;
}

.timeline {
  border-left: 1px solid #667DD1;
  position: absolute;
  top: 25px;
  left: -21px;
  height: 80%;
}

.overflowscrooimportant {
  height: 36vh !important;
  /* overflow-y: scroll !important; */
}


.circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #667DD1;
  position: absolute;
  left: -25px;
  top: 5px;
}




/* till here */
.table-theme-1 td .fullnamedata {
  padding-left: 1rem;
  padding-right: 1rem;
  text-transform: capitalize;
}

.table-theme-1 td {
  /* text-transform: capitalize; */
  font-weight: 500;
  color: #70747b;
  font-family: "GillSansMedium";
  position: relative;
  border: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  background-color: #fff !important;
  padding: 1rem 0.5rem;
  font-size: 1rem;
  text-align: left;
  padding-left: 2rem;
}


.bg_own_imp {
  background-color: #d3daf8 !important;
  color: #131523 !important;

}

.colour_imp {
  color: #667DD1 !important;
}

.table-theme-1 tr:hover td {
  background-color: #f7fafb !important;
}

.table-theme-1 td::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 5px;
  height: 1px;
}

.table-theme-1 td::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 5px;
  height: 1px;
}

.table-theme-goal th {
  font-family: "GillSansMedium";
  position: sticky;
  top: 0px;
  color: #70747b;
  font-weight: 400;
  padding: 0.5rem;
  font-size: 0.8rem;
  text-align: left;
  padding-left: 2rem;
}

.table-theme-goal tr {
  border-bottom: 1px solid #e6e6e9;
}


.desing1 {
  text-transform: capitalize;
  color: #70747b;
  font-size: 1rem;
  padding-left: 0.3rem;
  font-family: "GillSansMedium", sans-serif;
}

.table-theme-goal td {
  font-weight: 500;
  color: #131523;
  font-family: "GillSansMedium";
  position: relative;
  border: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  background-color: #fff;
  padding: 0.5rem 0rem;
  font-size: 0.8rem;
  text-align: left;
  padding-left: 2rem;
}

.main_bg_imp {
  background-color: #f5f7fb !important;
}

.subheadingtasks {
  padding-bottom: 1rem;
}

.homecontact {
  font-family: "GillSansMedium";
  color: #70747b;
  font-size: 1rem;
  padding-right: 5px;

  font-weight: 500;
}

.suboverviewcontact {
  color: #131523;
  font-family: "GillSansMedium";
  font-size: 1rem;
  padding-left: 5px;
  font-weight: 500;
  padding-bottom: 1rem;
}

.maincontactoverview {
  text-transform: uppercase;
  font-family: "GillSansMedium";
  color: #667DD1;
  font-size: 1.5rem;
}

.inputtypesearchbg {
  border-radius: 46px;
  background-color: #f5f5f5;
  color: #70747b;
  font-size: 0.8rem;
  font-family: "poppinsregular";
  outline: none !important;
  padding-left: 2rem;
}

.inputtypesearch {
  color: #70747b;
  font-size: 0.8rem;
  font-family: "poppinsregular";
  outline: none !important;
}

.Filters {
  font-family: "GillSansMedium";
  color: #131523;
  font-size: 5rem;
}

.homeoverview {
  margin-left: 2rem;
}

.serachhomepageallsearchbg {
  margin-top: 16px;
}

.w-100i {
  width: 100% !important;
}

.announcement-container {
  background-color: #fff;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  max-width: 600px;
  /* margin: 20px auto; */
  padding: 20px;
  border-radius: 10px;
}

.announcement-header {
  background-color: #f5f7fb;
  color: black;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
}

.gap5p5 {
  gap: 5.5rem !important;
}

.announcement-content {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.announcement-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.announcement-text {
  font-size: 18px;
  max-height: 15vh !important;
  line-height: 1.4;
}

.announcement-images {
  margin-top: 20px;
  /* display: flex; */
  justify-content: center;

}

.announcement-image {
  flex: 0.3;
  /* max-width: 150px; */
  height: 20vh;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.inviteuser .react-tel-input .form-control {
  background-color: #f5f5f5 !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 4px !important;
  padding: 0.5rem 12px !important;
  width: 85% !important;
  color: #70747b !important;
  margin-left: 15% !important;
  font-family: "GillSansMedium" !important;
  font-size: 0.9rem !important;
}

.announcement-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.announcement-footer {
  text-align: center;
  margin-top: 20px;
}

.announcement-button {
  background-color: #667DD1;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.announcement-button:hover {
  background-color: #667DD1;
}

.w-172pxi {
  width: 172px !important;
}

.overflowscroollimportant {
  overflow: scroll !important;
}


.table-theme-33 {
  border-bottom: 1px solid #f5f5f5 !important;
}

.selectbuttonsmalldesing1 {
  font-size: 12px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 13px;
  /* padding: 3px; */
  background-color: #667DD1;
  color: #ffffff;
  font-family: "GillSansMedium";
  display: flex;
  padding-bottom: 3px;
  padding-top: 3px;
  justify-content: center;
  align-items: center;
  margin-right: 7px;

}

@media (max-width: 1400px) {
  .theflexdesignwidht {
    display: block;
  }
}

@media (min-width: 1400px) {
  .theflexdesignwidht {
    display: flex;
  }
}

.Emailbutton {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 5px;
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: #667DD1;
  font-family: "GillSansMedium";
  border: 1px solid #667DD1;
}

.Emailbutton:hover {
  background-color: #667DD1;
  color: #fff;
  border: 1px solid #667DD1;
}

.emailbuttoncolor {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 5px;
  padding: 8px;
  font-family: "GillSansMedium";
  border: 1px solid #667DD1;
  color: #fff;
  background-color: #667DD1;
}

.buttonreports {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-radius: 5px;
  background-color: #667DD1;
  padding: 7px;
  color: #ffffff;
  font-family: "GillSansMedium";
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

.buttonsonlymarketing {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-radius: 5px;
  background-color: #667DD1;
  padding: 10px;
  color: #ffffff;
  font-family: "GillSansMedium";
  display: flex;
  justify-content: center;
  align-items: center;
}


.cursor_none_imp {
  cursor: default !important;
}


.padding_1rm_imp {
  padding: 1rem !important;
}

.margin_top_none_imp {
  margin-top: 0px !important;
}

.smallheader_design {
  margin-top: 0.5rem;
  cursor: pointer;
  background-color: #fdfdfd;
  border-radius: 10px;
  padding: 0.5rem;
  border: 1px solid #e6e6e9;
  display: flex;
  align-items: center;
}

.buttonsonlymarketing:hover {
  background-color: #8796d2;
}

.buttons {
  text-transform: capitalize;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-radius: 5px;
  background-color: #667DD1;
  padding: 10px;
  color: #ffffff;
  font-family: "GillSansMedium";
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons:active {
  background-color: #8796d2;
}

.buttons:hover {

  background-color: #8796d2;
}

.pospectsreportsData {
  font-size: 1rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-radius: 5px;
  color: #667DD1;
  background-color: #fff;
  padding: 7px;
  font-family: "GillSansMedium";
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #667DD1;
}

.settingbutton {
  font-size: 1rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-radius: 5px;
  background-color: #e6e6e9;
  padding: 8px;
  color: #70747b;
  font-family: "GillSansMedium";
  display: flex;
  justify-content: center;
  align-items: center;
}

.defaultparagraph_to_specific_design {
  font-family: "GillSansMedium";
  color: #70747b;
  font-size: 1rem;
  padding-right: 5px;
  font-weight: 500;
}

.settingbutton:hover {
  background-color: #667DD1;
  color: #ffff;
}

.sequensebuttoninycreate {
  font-size: 1rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-radius: 5px;
  background-color: #e6e6e9;
  padding: 8px;
  color: #70747b;
  font-family: "GillSansMedium";
  display: flex;
  justify-content: center;
  align-items: center;
}

.sequensebuttoninycreate:hover {
  background-color: #667DD1;
  color: #ffff;
}

.buttonviewreports {
  font-size: 1rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-radius: 5px;
  background-color: #e6e6e9;
  padding: 8px;
  color: #70747b;
  font-family: "GillSansMedium";
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-today {
  background-color: unset !important;
}

.buttonsequence {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-radius: 5px;
  background-color: #70747b;
  padding: 10px;
  color: #fff;
  font-family: "GillSansMedium";
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonviewreports:hover {
  background-color: #667DD1;
  color: #ffff;
}

.transactionmainheading {
  border-radius: 10px;
  border: 1px solid #e6e6e9;
  padding: 1.5rem;
}

.addtaskbutton {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 5px;
  background-color: #667DD1;
  color: #ffffff;
  font-family: "GillSansMedium";
}

.stepCounterButton {
  padding: 0.5rem 1rem;
  min-width: 75px;
}

.addtaskbutton:hover {
  background-color: #8796d2;
}

.tagbutton {
  font-size: 11px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 13px;
  background-color: #667DD1;
  color: #ffffff;
  font-family: "GillSansMedium";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}

.subfilter {
  font-weight: 500;
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-size: 1.3rem;
  color: #131523 !important;
  font-family: "GillSansMedium";
}

.addlisteningmain {
  background-color: #fff;
}

.mainfilterforsuggest {
  border-radius: 10px;
  background-color: #f5f7fb;
  padding-left: 2rem;
}

.mainfilter {
  border-radius: 10px;
  margin-bottom: 1rem;
  background-color: #f5f7fb;
  padding-left: 2rem;
}

.primaryowner h4 {
  font-weight: 500;
  padding-bottom: 5px;
  font-size: 0.9rem;
  color: #131523;
  font-family: "GillSansMedium";
}

.suggestmainbgcolorforsiggesticon {
  background-color: #fcfcfc;
  border-radius: 10px;
  padding-bottom: 1rem;
  box-shadow: 0px 1px 2px #00000029;
  opacity: 1;
  padding-left: 2rem;
}

.suggestinputtupeforgrey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 79%;
  padding-top: 1rem;
}

.primaryowner select:focus,
.primaryowner select:active {
  border: none !important;
  box-shadow: none;
  outline: none;
}

/* .activesuggest{
  background-color: #EAEEFF;
} */

.paginationcontacttable {
  padding: 0.8rem;
  background: #ffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.statusselect select:focus,
.statusselect select:active {
  border: none;
  box-shadow: none;
  outline: none;
}

/* .quickview {
  font-size: 0.8rem;
  font-family: "GillSansMedium";
  padding-bottom: 0.3rem;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
  background-color: #00000000;
  color: #f47b21;
  border-radius: 3px;
  border: 1px solid #f47b21;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quickview:hover {
  background-color: #fde5d3;
} */

.quickview {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #667DD1;
  width: 2rem;
  height: 2rem;
  z-index: 9999;
}

.quickview img {
  height: 18px;
  z-index: 9999;
}

/* .quickview{
  width: 24px;
} */

.Contact select {
  font-family: "GillSansLight";
  color: #000000cc;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  width: 84%;
  background-color: #f5f5f5;
}

.Contact select:focus,
.Contact select:active {
  border: none;
  box-shadow: none;
  outline: none;
}

.typeselect select {
  font-family: "GillSansLight";
  color: #000000cc;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  width: 13%;
  background-color: #f5f5f5;
}

.typeselect select:focus,
.typeselect select:active {
  border: none;
  box-shadow: none;
  outline: none;
}

.primaryowner {
  padding-bottom: 2rem;
}

.filterinputscontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 58%;
}

.filterinputsonlytransaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 38%;
}

.filterinputsmaster {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 31%;
}

.filterinputssuggestpage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 33%;
}

.fontsize1rem {
  font-size: 1rem !important;
}

.globaltextformationforpopups {
  color: #70747b !important;
  font-size: 0.9rem;
  margin-right: 5px !important;
}

.css-i4bv87-MuiSvgIcon-root {
  /* -webkit-user-select: none; */

  /* user-select: none; */
  /* width: 1em; */
  /* height: 1em; */
  /* display: inline-block; */
  margin-left: 2%;
  /* fill: currentColor; */
  margin-top: 21px;
}

.allinputinsidepopuptextstyle {
  color: #131523;
  font-size: 0.9rem;
  font-weight: 500;
  padding-bottom: 5px;
}


.filterinputscontactspage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 62%;
  /* width: 30%; */
}

.filterinputscontactschoose {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}

.filterinputscontents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 47%;
}

.usersectionfilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 47%;
}

.filterinputstasks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  /* width: 58%; */
}

.filteranalyzedatableinput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 48%;
}

.filterinputscontentteamreports {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* width: 82%; */
}

.filterinputscontent .filterinputscontentaddress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover {
  background-color: none !important;
  box-shadow: none !important;
}

.MuiPagination-ul .Mui-selected {
  font-family: "GillSansMedium" !important;
  /* min-width: 0px !important; */
  color: #ffac67 !important;
  background-color: none !important;
  /* height: 0px !important; */
  background: unset !important;
}

.MuiPaginationItem-root {
  font-size: 1rem !important;
  color: #70747b !important;
  font-family: "GillSansMedium" !important;
}

.MuiPaginationItem-root:hover {
  background-color: unset !important;
}

.paginationtextandcontent {
  font-family: "GillSansMedium" !important;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  background: #ffff;
}

.paginationsubdata h3 {
  color: #70747b;
  font-size: 0.8rem;
}

.mobile-menu li {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 18px;
  color: #8892a6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px;
  /* border-bottom: 1px solid #71738191; */
}

.mobile-menu li:last-child {
  border-bottom: none;
}

.Layout {
  display: flex;
}

.mobile-menu li ul {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease;
}

.mobile {
  max-height: 500px !important;
}

.mobile-menu li a {
  color: #70747b;

  font-weight: 500;
  transition: font-weight 0.3s ease-in-out;
}

.side-menu li a.active {
  color: #ffff;
  font-family: GillSansMedium;

}

.header {
  z-index: 999;
  background-color: #fff !important;
  /* position: sticky;
  top: 0; */
  border-bottom: 1px solid #e6e6e9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.editDeleteModal {
  background-color: #ffffff;
  padding: 1rem;
  /* padding-right: 2rem; */
  border-radius: 4px;
  box-shadow: 0px 6px 5px -2px rgb(0 0 0 / 20%) !important;
  position: absolute;
  right: 0;
}

.moreIcon {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
}

.moreIcon h3 {
  color: #70747b;
  font-size: 0.9rem;
  padding-right: 0rem;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #667DD1 !important;
  stroke-width: 3px;
}


.kXPGqK .blockbuilder-branding {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  visibility: hidden;
  left: 0px;
  right: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 50px;
  background: rgb(238, 238, 238);
  border-top: 1px solid rgb(214, 217, 220);
  z-index: 100;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 1.2rem !important;
  fill: #667DD1 !important;
  font-family: "GillSansMedium";
  font-weight: 400;
}

.containerumlayer {
  position: relative;
  /* Add other styles for containerumlayer */
}

.justify_center_imp {
  justify-content: center !important;
}

.required_height {
  height: calc(100vh - 561.83px);
  overflow: auto;
}

.email-editor-wrapper {
  position: relative;
  /* Add other styles for email-editor-wrapper */
}

.blueDiv {
  position: absolute;
  /* top: 0; */
  right: 0;
  bottom: 0;
  height: 7vh;
  width: 29%;
  background-color: #eaeeff;
  text-align: center;
  /* Add other styles for your blue div */
}


.CircularProgressbar .CircularProgressbar-trail {
  stroke-width: 3px !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: "GillSansMedium" !important;
  font-size: 0.8rem !important;
  color: #131523;
}

.calendars {
  display: grid;
  row-gap: 10rem;
}

.sidemobile {
  display: none;
}

.MT15 {
  margin-top: 1.5rem !important;
}

.chartsreports {
  height: 200px !important;
  width: 200px !important;
}

.openSidebar li {
  animation: type 2s infinite;
  opacity: 0;
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-animation-timing-function: cubic-bezier(0.45, 0.005, 0, 1);
  -moz-animation-timing-function: cubic-bezier(0.45, 0.005, 0, 1);
  -o-animation-timing-function: cubic-bezier(0.45, 0.005, 0, 1);
  animation-timing-function: cubic-bezier(0.45, 0.005, 0, 1);
}

.openSidebar li:nth-child(2) {
  animation-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.openSidebar li:nth-child(3) {
  animation-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  -ms-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.openSidebar li:nth-child(4) {
  animation-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.openSidebar li:nth-child(5) {
  animation-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.openSidebar li:nth-child(6) {
  animation-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  -moz-transition-delay: 0.6s;
  -ms-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.openSidebar li:nth-child(7) {
  animation-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
  -moz-transition-delay: 0.7s;
  -ms-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.openSidebar li:nth-child(8) {
  animation-delay: 0.8s;
  -webkit-transition-delay: 0.8s;
  -moz-transition-delay: 0.8s;
  -ms-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.openSidebar li:nth-child(9) {
  animation-delay: 0.9s;
  -webkit-transition-delay: 0.9s;
  -moz-transition-delay: 0.9s;
  -ms-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.openSidebar li:nth-child(10) {
  animation-delay: 0.10s;
  -webkit-transition-delay: 0.10s;
  -moz-transition-delay: 0.10s;
  -ms-transition-delay: 0.10s;
  -o-transition-delay: 0.10s;
  transition-delay: 0.10s;
}

.openSidebar li:nth-child(11) {
  animation-delay: 0.11s;
  -webkit-transition-delay: 0.11s;
  -moz-transition-delay: 0.11s;
  -ms-transition-delay: 0.11s;
  -o-transition-delay: 0.11s;
  transition-delay: 0.11s;
}

.openSidebar li:nth-child(12) {
  animation-delay: 0.12s;
  -webkit-transition-delay: 0.12s;
  -moz-transition-delay: 0.12s;
  -ms-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s;
}

@keyframes type {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.mobilemenu {
  display: block;
  position: absolute;
  z-index: 99999;
}

.hide {
  display: none;
  position: relative;
}


@media (max-width: 2500px) {
  .quickview {
    position: absolute;
    /* top: -7px; */
    left: 10px;
    z-index: 99;
  }
}

@media (max-width: 1800px) {
  .quickview {
    position: absolute;
    /* top: -7px; */
    left: -11px;
    z-index: 99;
  }
}

.myDIV:hover>.hide {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Toggle {
  font-size: 0.9rem;
  font-family: "GillSansMedium";
  align-items: center;
  background-color: #ffff;
  border: 2px solid #667DD1;
  border-radius: 3rem;
  /* color: #667DD1; */
  display: flex;
  /* height: 2rem; */
  justify-content: space-around;
  padding: 12px;
  position: relative;
  transition: all 0.2s ease-out;
  width: 4rem;
}

.Togglenotification {
  font-size: 0.9rem;
  font-family: "GillSansMedium";
  align-items: center;
  background-color: #ffff;
  border: 2px solid #667DD1;
  border-radius: 3rem;
  /* color: #667DD1; */
  display: flex;
  /* height: 2rem; */
  justify-content: space-around;
  padding: 12px;
  position: relative;
  transition: all 0.2s ease-out;
  width: 3.5rem;
}

.Toggle16 {
  font-size: 0.9rem;
  font-family: "GillSansMedium";
  align-items: center;
  background-color: #ffff;
  border: 2px solid #667DD1;
  border-radius: 3rem;
  /* color: #667DD1; */
  display: flex;
  /* height: 2rem; */
  justify-content: space-around;
  padding: 12px;
  position: relative;
  transition: all 0.2s ease-out;
  width: 5rem;
}


.w-5emi {
  width: 5rem !important;
}


.open_closeview .css-i4bv87-MuiSvgIcon-root {
  margin-top: 0px !important;
}

.Toggle2 {
  font-size: 0.9rem;
  font-family: "GillSansMedium";
  align-items: center;
  background-color: #ffff;
  border: 2px solid #667DD1;
  border-radius: 3rem;
  /* color: #667DD1; */
  display: flex;
  /* height: 2rem; */
  justify-content: space-around;
  padding: 12px;
  position: relative;
  transition: all 0.2s ease-out;
  width: 5rem;
}

.ToggleWidthDown {
  width: 3.5rem;
}


.ActiveToggleSlider22 {
  background-color: #667DD1;
  border: 2px solid #667DD1;
  border-radius: 50%;
  content: "";
  left: 11px;
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  transition: all 0.2s ease-out;
  /* z-index: 99; */
  transform: translateX(1.8rem);
}

.ToggleWidthDown2nd {
  width: 4.2rem;
}

.ToggleWidthDown3rd {
  width: 5.2rem;
}

.left29imp {
  left: 29px !important;
}

.ToggleForSetting {
  border: 2px solid #70747b;
  color: #70747b;
}



.Toggle .sliderForSetting {
  background-color: #70747b;
  border: 2px solid #70747b;
}

.Togglenotification .sliderForSetting {
  background-color: #70747b;
  border: 2px solid #70747b;
}

.Toggle16 .sliderForSetting {
  background-color: #70747b;
  border: 2px solid #70747b;
}


.Toggle .text {
  font-size: 0.7rem;
  font-family: "GillSansMedium";
  position: absolute;
  left: 8px;
  z-index: 0;
}

.Togglenotification .text {
  font-size: 0.7rem;
  font-family: "GillSansMedium";
  position: absolute;
  left: 8px;
  z-index: 0;
}

.iconsdesign {
  color: #667DD1 !important;
  margin-top: 0px !important;
}

.Toggle16 .text {
  font-size: 0.7rem;
  font-family: "GillSansMedium";
  position: absolute;
  left: 8px;
  z-index: 0;
}

.width_Height_1p2emimp {
  width: 1.2em !important;
  height: 1.2em !important;
}

.Toggle .textright {
  font-size: 0.8rem;
  font-family: "GillSansMedium";
  position: absolute;
  right: 8px;
  z-index: 0;
}

.Togglenotification .textright {
  font-size: 0.8rem;
  font-family: "GillSansMedium";
  position: absolute;
  right: 8px;
  z-index: 0;
}

.Toggle16 .textright {
  font-size: 0.8rem;
  font-family: "GillSansMedium";
  position: absolute;
  right: 8px;
  z-index: 0;
}


.Toggle input:checked+.slider {
  background-color: #6a7abc;
  border: 2px solid #6a7abc;
  transform: translateX(2.3rem);
}

.Togglenotification input:checked+.slider {
  background-color: #6a7abc;
  border: 2px solid #6a7abc;
  transform: translateX(2.3rem);
}

.Toggle16 input:checked+.slider {
  background-color: #6a7abc;
  border: 2px solid #6a7abc;
  transform: translateX(2.3rem);
}

.Toggle .hometoggle {
  background-color: #667DD1 !important;
}

.Togglenotification .hometoggle {
  background-color: #667DD1 !important;
}

.Toggle16 .hometoggle {
  background-color: #667DD1 !important;
}

.w9vwi {
  width: 9vw !important;
}

.activetoggleborder {
  border: 2px solid #667DD1 !important;

}

.Toggle .slider {
  background-color: #70737b;
  border: 2px solid #70737b;
  border-radius: 50%;
  content: "";
  left: 2px;
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  transform: translateX(0);
  transition: all 0.2s ease-out;
  /* z-index: 99; */
}

.Togglenotification .slider {
  background-color: #70737b;
  border: 2px solid #70737b;
  border-radius: 50%;
  content: "";
  left: -5px;
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  transform: translateX(0);
  transition: all 0.2s ease-out;
  /* z-index: 99; */
}

.Toggle16 .slider {
  background-color: #70737b;
  border: 2px solid #70737b;
  border-radius: 50%;
  content: "";
  left: 16px;
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  transform: translateX(0);
  transition: all 0.2s ease-out;
  /* z-index: 99; */
}

.grayBorder {
  border: 2px solid #70747B;
}

.ActiveToggleSlider {
  background-color: #667DD1;
  border: 2px solid #667DD1;
  border-radius: 50%;
  content: "";
  left: 2px;
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  transition: all 0.2s ease-out;
  /* z-index: 99; */
  transform: translateX(1.8rem);
}


.ActiveToggleSlider2 {
  background-color: #667DD1;
  border: 2px solid #667DD1;
  border-radius: 50%;
  content: "";
  left: 9px;
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  transition: all 0.2s ease-out;
  /* z-index: 99; */
  transform: translateX(1.8rem);
}

.Toggle input[type="checkbox"],
.customCheckboxMulti input[type="checkbox"] {
  display: none;
}

.Togglenotification input[type="checkbox"],
.customCheckboxMulti input[type="checkbox"] {
  display: none;
}

.Toggle16 input[type="checkbox"],
.customCheckboxMulti input[type="checkbox"] {
  display: none;
}


.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: unset !important;
}

.css-1nq51we {
  top: 85px !important;
  box-shadow: -1px 0px 1px #e6e6e9 !important;
}

.createhtmlmodal {
  margin-bottom: 0rem !important;
}

.createhtmlmodal {
  background-color: #f5f7fe !important;
}

.createhtmlmodal .MuiButtonBase-root {
  padding: 0px 16px !important;
  /* padding-bottom: 8px !important; */
}

.createhtmlmodal .MuiAccordionDetails-root {
  padding: 0px 24px 0px !important;
}

/* .contactpreviewscrollbar .MuiPaper-root .MuiPaper-elevation{
  margin-bottom: 17px !important;

} */
.contactpreviewscrollbar .MuiButtonBase-root {
  padding: 1.5rem !important;
  /* padding-bottom: 8px !important; */
}

.contactpreviewscrollbar .Mui-expanded {
  padding-bottom: 0px !important;
}

.MuiAccordionDetails-root {
  padding: 0px 24px 16px !important;
}

.previewscrollforaccordiandetails .MuiAccordionDetails-root {
  background-color: #ffff !important;
}

.previewscrollforaccordiandetails .MuiAccordionDetails-root {
  padding: 0px 54px 1.5rem !important;

  border-top: none !important;
}

.previewscrollforaccordiandetails .MuiButtonBase-root {
  background-color: #ffff !important;
}

.isbackgroundcolorchange .MuiAccordionDetails-root {
  padding: 0px 45px 16px !important;
  background-color: #fafbfb !important;
  border-top: none !important;
}

.MuiPaper-root .MuiPaper-elevation {
  margin-top: 1.5rem !important;
}

@media (min-width: 1400px) {
  .paddinleftmin1400 {
    padding-left: 12px;
  }
}

@media (max-width: 1400px) {
  .paddinleftmin1400 {
    padding-left: 0.8rem;
  }
}

@media (max-width: 1400px) {
  .contactdata {
    width: 50vw !important;
  }
}

/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  color: #fff !important;
  background-color: #fafbfb !important;
  box-shadow: none !important;
  border-radius: 10px !important;
} */

.MuiPaper-root {
  color: #fff !important;
  background-color: #f5f7fb !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  border: unset !important;
  margin-bottom: 1rem !important;
}

.createhtmlmodal {
  background-color: #f5f7fe !important;
}

.contactpreviewsimpleaccordian .MuiPaper-root {
  color: #131523 !important;
  /* background-color:#F5F7FE !important; */
  box-shadow: none !important;
  border-radius: 10px !important;
  border: unset !important;
  margin-bottom: 2rem;
  font-size: 0.9rem;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type {
  margin-bottom: 2rem;
}

.css-ta84vm-MuiAccordionDetails-root {
  padding: 0 !important;
  padding-left: 2.5rem !important;
}

.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: #f5f5f5 !important;
}

.css-ta84vm-MuiAccordionDetails-root {
  background-color: #f5f5f5;
  border-top: unset !important;
  padding-bottom: 2rem !important;
}

.previewscrollforaccordiandetails .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
  background-color: #fff !important;
  border-radius: 10px !important;
  border-top: unset !important;
}

.isbackgroundcolorchange .MuiButtonBase-root {
  border-radius: 10px !important;
  background-color: #fafbfb !important;
  border-top: unset !important;
}

.MuiAccordionSummary-expandIconWrapper {
  margin-top: -4rem !important;
  transform: rotate(90deg) !important;
}

.onlypropertiescontact .MuiAccordionSummary-expandIconWrapper {
  margin-top: -2rem !important;
  transform: rotate(90deg) !important;
}

.contactclosingonlyforexpandmore .MuiAccordionSummary-expandIconWrapper {
  margin-top: -2rem !important;
  transform: rotate(90deg) !important;
}

.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(272deg) !important;
}

.emailpreviewsimpleaccordian .MuiAccordionSummary-expandIconWrapper {
  margin-top: 0rem !important;
  transform: rotate(0deg) !important;
}

.contactpreviewsimpleaccordian .MuiAccordionSummary-expandIconWrapper {
  margin-top: -2rem;
  transform: rotate(0deg) !important;
  margin-right: 2.25rem;
}

.addhtmlemail .MuiAccordionSummary-expandIconWrapper {
  margin-top: 0rem !important;
  transform: rotate(88deg) !important;
}

.contactpreviewsimpleaccordian .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(271deg) !important;
}

.onlycontactprevewopencursor .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(180deg) !important;
}




.contactpreviewsimpleaccordian {
  margin-bottom: 13px;
  margin-top: 1rem;
}

.emailpreviewsimpleaccordian {
  margin-top: 0rem !important;
}

.css-19kzrtu {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.hiddencheckbox [type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.hiddenradio [type="checkbox"]+img {
  cursor: pointer;
}


.hiddenradio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


.hiddenradio [type="radio"]+img,
.calendarRadio [type="radio"]+.radioBtnEventName {
  cursor: pointer;
}

.calendarRadio [type="radio"]:checked+.listend {
  background-color: #6A7ABC;
  color: #fff !important;
  border: 1px solid #6A7ABC !important;
}

.calendarRadio [type="radio"]:checked+.ListingDotEventName {
  background-color: #E7EBFB;
  color: #131523 !important;
  border: 1px solid #E7EBFB !important;
}

.calendarRadio [type="radio"]:checked+.ListingDotEventName p {
  color: #131523 !important;
}

.calendarRadio [type="radio"]:checked+.ListingDotEventName p {
  color: #131523 !important;
}

.ActiveListingShow {
  background-color: #E7EBFB;
  color: #131523 !important;
  border: 1px solid #E7EBFB !important;
}

.calendarRadio [type="radio"]:checked+.listend p {
  color: #ffff !important;
}

.calendarRadio [type="radio"]:checked+.BuyerDotEventName {
  background-color: #ffedde;
  color: #131523 !important;
  border: 1px solid #ffedde !important;
}

.calendarRadio [type="radio"]:checked+.BuyerDotEventName p {
  color: #131523 !important;
}

.ActiveBuyerShow {
  background-color: #ffedde;
  color: #131523 !important;
  border: 1px solid #ffedde !important;
}

.calendarRadio [type="radio"]:checked+.MeetingDotEventName {
  background-color: #EBF8FA;
  color: #131523 !important;
  border: 1px solid #EBF8FA !important;
}

.calendarRadio [type="radio"]:checked+.OtherDotEventName {
  background-color: #e6e6e9;
  color: #131523 !important;
  border: 1px solid #e6e6e9 !important;
}

.calendarRadio [type="radio"]:checked+.CmaDotEventName {
  background-color: #d3ffe9ab;
  color: #131523 !important;
  border: 1px solid #d3ffe9ab !important;
}


.ActiveOtherDotEventName {
  background-color: #e6e6e9;
  color: #131523 !important;
  border: 1px solid #e6e6e9 !important;
}


.overflow-wrap {
  overflow-wrap: break-word;
}



.coloruniversal {
  color: "#667DD1" !important;
}

.ActiveCmaEventName {
  background-color: #d3ffe9ab;
  color: #131523 !important;
  border: 1px solid #d3ffe9ab !important;
}


.ActiveMeetingShow {
  background-color: #EBF8FA;
  color: #131523 !important;
  border: 1px solid #EBF8FA !important;
}

.PhoneRadioBg img {
  height: 18px;
}

.PhoneRadioBg {
  border: 2px solid #EF6C00;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.customTaskCheckbox [type="checkbox"]:checked+.PhoneRadioBg {
  background-color: #EF6C00;
  border: none !important;
}

.customTaskRadio [type="radio"]:checked+.PhoneRadioBg,
.CheckedPhone {
  background-color: #EF6C00;
  border: none !important;
}

.col_span_2 {
  grid-column: span 2 / span 2 !important;
}


.newsmstemplatewithoutheight {
  padding: 1.5rem;
  height: calc(100vh - (16vh + 197.36px));
  overflow: auto;
  padding-top: 0rem !important;
}


.text_transformation_none {
  text-transform: none !important;
}

/* .emailRadioBg img {
  height: 18px;
} */

.emailRadioBg {
  border: 2px solid #E46776;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.emailRadioBg {
  border: 2px solid #E46776;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.customTaskCheckbox [type="checkbox"]:checked+.eventemail {
  background-color: red;
  border: none !important;
}

.customTaskCheckbox [type="checkbox"]:checked+.emailRadioBg {
  background-color: #E46776;
  border: none !important;
}

.customTaskRadio [type="radio"]:checked+.emailRadioBg {
  background-color: #E46776;
  border: none !important;
}

.todoRadioBg img {
  height: 18px;
}

.todoRadioBg {
  border: 2px solid #92a9f7;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.customTaskRadio [type="radio"]:checked+.todoRadioBg {
  background-color: #92a9f7;
  border: none !important;
}

.customTaskRadio [type="radio"]:checked+.meetingradiobggreen {
  background-color: #81d5dd;
  border: none !important;
}

.customTaskCheckbox [type="checkbox"]:checked+.meetingradiobggreen {
  background-color: #81d5dd;
  border: none !important;
}

.customTaskCheckbox [type="checkbox"]:checked+.todoRadioBggreen {
  background-color: #95cead;
  border: none !important;
}

.todoRadioBggreen img {
  height: 18px;
}

.meetingradiobggreen img {
  height: 18px;
}

.todoRadioBggreen {
  border: 2px solid #95cead;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.meetingradiobggreen {
  border: 2px solid #81d5dd;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.customTaskRadio [type="radio"]:checked+.todoRadioBggreen {
  background-color: #95cead;
  border: none !important;
}

.ToggleRadioBg {
  border: 2px solid #e1e8fd;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.margin_right_10pximp {
  margin-right: 10px !important;
}

.customTaskRadio [type="radio"]:checked+.ToggleRadioBg {
  background-color: #e1e8fd;
  border: none !important;
}


.calendarRadio [type="radio"]:checked+.MeetingDotEventName p {
  color: #131523 !important;
}

.calendarRadio [type="radio"]:checked+.OtherDotEventName p {
  color: #131523 !important;
}

.calendarRadio [type="radio"]:checked+.CmaDotEventName p {
  color: #131523 !important;
}

.calendarRadio [type="radio"] {
  display: none;
}

/* 
[type=radio]:checked+img {
  outline: 2px solid #f00;
} */

.radioBtnEventName {
  font-family: "GillSansMedium";
  border: 1px solid #70747b;
  border-radius: 14px;
  /* height: 2rem; */
  padding: 3px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px !important;
}

.radioBtnEventName2 {
  font-family: "GillSansMedium";
  border: 1px solid #70747b;
  border-radius: 14px;
  /* height: 2rem; */
  padding: 3px;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px !important;
}

.radioBtnEventName2 p {
  font-size: 12px;
}

.ListingDot,
.BuyerDot,
.MeetingDot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 2px;
}

.ListingDot {
  background-color: #667DD1;
}

.BuyerDot {
  background-color: #f47b21;
}

.MeetingDot {
  background-color: #eded65;
}

.radioBtnEventName p {
  font-size: 12px;
  color: #70747b;

}

.addtaskmodalcustom {
  animation: right-left ease 0.3s;
}

.css-919eu4 {
  background-color: unset !important;
}

.css-12v8nzs {
  top: 83px !important;
  box-shadow: -1px 0px 1px #e6e6e9 !important;
}

.calendarModalMain .css-12v8nzs {
  min-height: calc(100vh - 81.97px) !important;
}

.css-12v8nzs:focus-visible {
  outline: none !important;
}

.emailtemplates {
  right: 0px;
  z-index: 999;
  position: absolute;
  margin-top: 5px;
  padding: 1rem;
  background-color: #ffffff;
  width: 160px;
  box-shadow: 0px 1px 4px #00000029;
}

.textpadding {
  padding-bottom: 5px;
}

.newtemplates {
  position: relative;
}

.emailtemplates h3 {
  color: #70747b;
  font-size: 1rem;
}

.dmajdp>span {
  font-size: 12px !important;
  color: #70747b !important;
}

.dmajdp {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.jWkLDY>span {
  font-size: 0.9rem !important;
}

.AddlistingImages {
  border-radius: 10px;
  padding: 54px !important;
  background-color: #fff !important;
  /* max-width: unset !important;
  min-width: unset !important; */
  border: dashed 1px #667DD1 !important;
}

.AddlistingImages2nd {
  border-radius: 10px;
  padding: 54px !important;
  /* background-color: #fff !important; */
  /* max-width: unset !important;
  min-width: unset !important; */
  border: dashed 1px #667DD1 !important;
}

.AddlistingImages label {
  border: unset !important;
  max-width: unset !important;
  min-width: unset !important;
}


.borderbottomuniversal {
  border-color: #667DD1;
}

.AddDocumentfile {
  border-radius: 10px;
  /* max-width: unset !important; */
  background-color: #f4f7fc !important;
  /* padding: 4rem !important; */
  border: dashed 1px #667DD1 !important;
}

.AddDocumentfile label {
  border: unset !important;
  max-width: unset !important;
}

label svg {
  display: none !important;
}



.bXgVCj span {
  text-decoration: none !important;
}

@keyframes right-left {
  0% {
    transform: translateX(700px);
  }

  100% {
    transform: translateX(0px);
  }
}

.createemaileditor .rdw-fontfamily-wrapper {
  display: block !important;
}


.createemaileditorsecond .rdw-fontfamily-wrapper {
  display: block !important;
}

.rdw-text-align-wrapper {
  display: none !important;
}

.rdw-remove-wrapper {
  display: none !important;
}

.rdw-option-wrapper:nth-child(4) {
  display: none !important;
}

.rdw-option-wrapper:nth-child(5) {
  display: none !important;
}

.rdw-option-wrapper:nth-child(6) {
  display: none !important;
}

.rdw-option-wrapper:nth-child(7) {
  display: none !important;
}

.rdw-fontfamily-wrapper {
  display: none !important;
}

.rdw-fontsize-wrapper {
  display: none !important;
}

.createemaileditor .rdw-fontsize-wrapper {
  display: block !important;
}


.createemaileditorsecond .rdw-fontsize-wrapper {
  display: block !important;
}

.createemaileditor .rdw-fontsize-wrapper .rdw-dropdown-optionwrapper {

  top: -275px !important;
}

.createemaileditorsecond .rdw-fontsize-wrapper .rdw-dropdown-optionwrapper {

  top: auto !important;
}

.createemaileditor .rdw-block-wrapper {
  display: block !important;
}

.createemaileditorsecond .rdw-block-wrapper {
  /* display: block !important; */
}

.padding_design {
  padding: 1.2rem 1.2rem;
}

.createemaileditor .rdw-block-wrapper .rdw-dropdown-optionwrapper {

  top: -260px !important;
}

.createemaileditorsecond .rdw-block-wrapper .rdw-dropdown-optionwrapper {

  top: auto !important;
}

.rdw-block-wrapper {
  display: none !important;
}

.createemaileditor .rdw-emoji-wrapper {
  display: block !important;
}

.createemaileditorsecond .rdw-emoji-wrapper {
  display: block !important;
}

.rdw-option-disabled {
  display: none !important;
}

.rdw-emoji-wrapper {
  display: none !important;
}

.createemaileditor .rdw-colorpicker-wrapper {
  display: block !important;
}

.createemaileditorsecond .rdw-colorpicker-wrapper {
  display: block !important;
}

.rdw-colorpicker-wrapper {
  display: none !important;
}

.createemaileditor .rdw-list-wrapper {
  display: flex !important;
}

.createemaileditorsecond .rdw-list-wrapper {
  display: flex !important;
}

.addlisting .rdw-list-wrapper,
.meeting .rdw-list-wrapper,
.emailmettingbodyareanew .rdw-list-wrapper,
.newsmsbodyareanew .rdw-list-wrapper,
.newsmsbodyareanew .rdw-list-wrapper .noteaddmodal .rdw-list-wrapper {
  display: flex !important;
}

.noteaddmodal .rdw-image-wrapper,
.meeting .rdw-image-wrapper {
  display: none !important;
}


.emailmettingbodyareanew .rdw-editor-wrapper {
  flex-direction: column !important;

}



.rdw-list-wrapper {
  display: none !important;
}

.createemaileditor .rdw-text-align-wrapper {
  display: flex !important;
}

.createemaileditorsecond .rdw-text-align-wrapper {
  display: flex !important;
}

.rdw-text-align-wrapper {
  display: none !important;
}

.createemaileditor .rdw-embedded-wrapper {
  display: block;
}

.createemaileditorsecond .rdw-embedded-wrapper {
  display: block;
}

.rdw-embedded-wrapper {
  display: none !important;
}

.createemaileditor .rdw-history-wrapper {
  display: flex !important;
}

.createemaileditorsecond .rdw-history-wrapper {
  display: flex !important;
}

.rdw-remove-wrapper {
  display: none !important;
}

.rdw-history-wrapper {
  display: none !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.createemaileditor .rdw-editor-wrapper {
  margin-right: 0rem;
  margin-left: 0rem;
}

.createemaileditorsecond .rdw-editor-wrapper {
  margin-right: 0rem;
  margin-left: 0rem;
  display: block !important;
}

.createemaileditor .rdw-editor-toolbar {
  padding: 0px 0px 0;
}

.createemaileditorsecond .rdw-editor-toolbar {
  padding: 0px 0px 0;
}

.rdw-editor-wrapper {
  margin-right: 1rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column-reverse;
}

.rdw-option-wrapper {
  background: none !important;
  border: none !important;
  padding: 0px !important;
  min-width: none !important;
}

.rdw-editor-main {
  padding-bottom: 6rem;
}


.rdw-editor-toolbar {
  border: none !important;
  border-radius: 0px !important;
}

.public-DraftEditorPlaceholder-root {
  color: #70747b;
  font-size: 1rem;
  font-family: "GillSansMedium";
}

.DraftEditor-editorContainer {
  color: #70747b;
  font-size: 1rem;
  font-family: "GillSansMedium";
}

.meeting .rdw-editor-toolbar {
  display: flex;
  justify-content: flex-end;
  background: none !important;
}

.meeting .rdw-editor-main {
  padding: 0rem;
}

.addlisting .rdw-editor-toolbar {
  display: flex;
  justify-content: flex-end;
  background: none !important;
}

.addlisting .rdw-editor-main {
  padding: 0rem;
}

.noteaddmodal .rdw-editor-main {
  padding: 0rem !important;
  min-height: calc(100vh - (26vh + 136.38px)) !important;
  overflow: auto !important;
}

/* Define a CSS class */
.custom-svg {
  /* Define your custom styles here */
  fill: #ff0000;
  /* Example fill color (red) */
  stroke: #00ff00;
  /* Example stroke color (green) */
  stroke-width: 2px;
  /* Example stroke width */
}


.meeting {
  border-bottom: 1px solid #e6e6e9;
}

.phonecall {
  border-bottom: unset !important;
}

.logcall {
  border-bottom: unset !important;
}

.meeting .rdw-editor-wrapper {
  border-radius: 10px;
  margin-left: 0rem;
  margin-right: 0rem;
  margin-bottom: 1rem;
  overflow: scroll !important;
  padding-left: 1rem;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}

.mastertabselectbutton .css-zn1xfs-control {
  cursor: pointer !important;
}

.buttondesingsfortab {
  color: #1d1b1b !important;
  /* font-size: 1rem !important; */
  font-family: "GillSansMedium" !important;
}

.meeting .public-DraftEditor-content[contenteditable=true] {
  -webkit-user-modify: read-write-plaintext-only;
  overflow: scroll;
}

/* Remove background color on pasted text */
.meeting .public-DraftEditor-content .public-DraftStyleDefault-pre {
  background: none !important;
  color: inherit !important;
}


.listing .rdw-editor-wrapper {
  margin-top: 1rem;
  padding-top: 10px;
  background-color: #fff !important;
  border: 1px solid #fff !important;
}

.listing .public-DraftStyleDefault-block {
  margin: 0em 0 !important;
}

.addlisting .public-DraftStyleDefault-block {
  margin: 0em 0 !important;
  margin-top: 5px !important;
  height: 63px;
  overflow: auto !important;
}

.listing .wrapperClassName .editorClassName .DraftEditor-root .DraftEditor-editorContainer .notranslate {
  height: 100px !important;
  overflow: auto !important;
}

/* 
//createemaileditor */
.createemaileditorsecond .rdw-editor-main {

  min-height: 311px !important;
  overflow: auto !important;
}

.listing .wrapperClassName .editorClassName .DraftEditor-root .DraftEditor-editorContainer .notranslate {
  height: 104px !important;
  overflow: auto !important;
}

.emailmeetingbodyarea .rdw-editor-wrapper {
  margin-bottom: 0rem;
  height: 46vh;


}


.inviteuser .PhoneInputInput {
  background-color: #f5f5f5;
}

.inviteuser2 .PhoneInputInput {
  background-color: #fff;
}

.emailmeetingbodyarea .public-DraftStyleDefault-block {
  margin: none !important;
}

.createemaileditor .createemaileditorsecond .rdw-editor-wrapper {
  display: block !important;
}

.border_bottom_tabs {
  border-bottom: 0.0001rem solid #e1e1e1 !important;
}




.h67vhi {
  height: "67vh" !important;
}

.addlisting .rdw-editor-wrapper {
  border-radius: 10px;
  margin-left: 0rem;
  margin-right: 0rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  background-color: #fff;
  border: 1px solid #fff;
  width: 92%;
}

.addlisting .rdw-editor-wrapper {
  border-radius: 10px;
  margin-left: 0rem;
  margin-right: 0rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  background-color: #fff;
  border: 1px solid #fff;
  width: 92%;
}

.addlisting .rdw-editor-wrapper:active,
.addlisting .rdw-editor-wrapper:focus {
  border: 1px solid #667DD1 !important;
}


.propertydescription .rdw-editor-wrapper {
  height: 145px !important;
  margin-top: 1rem;
  width: 99%;
}

.addlisting .rdw-image-wrapper,
.rdw-link-wrapper {
  display: none !important;
}

.features {

  width: 92% !important;
}




/* calendar start */
.buyerEvent,
.ListingEvent,
.otherevent,
.CmaEventName,
.MeetingEvent {
  padding: unset !important;
  /* text-transform: capitalize !important; */
  color: #131523 !important;
  display: flex;
  align-items: center;
  border-radius: 5px !important;
}

.rbc-off-range-bg {
  background: #f5f5f5 !important;
}

.buyerEvent {
  background-color: #feeee2 !important;
}

.ListingEvent {
  background-color: #E7EBFB !important;
}

.otherevent {
  background-color: #e6e6e9 !important;
}

.MeetingEvent {
  background-color: #EBF8FA !important;
}

.CmaEventName {
  background-color: #d3ffe9ab !important;
}

.buyerEvent:focus,
.ListingEvent:focus,
.otherevent:focus,
.CmaEventName:focus,

.MeetingEvent:focus {
  outline: none !important;
}

.buyerEvent::before,
.ListingEvent::before,
.CmaEventName::before,
.otherevent::before,

.MeetingEvent::before {
  content: "";
  position: absolute;
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
}

/* .CmaEventName::before {
  content: "";
  position: absolute;
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
} */

.rbc-day-slot .rbc-event::before {
  content: "";
  display: none !important;
}

.buyerEvent::before {
  background-color: #f47b21;
}

.ListingEvent::before {
  background-color: #667DD1;
}

.CmaEventName::before {
  background-color: #d3ffe9ab;
}

.MeetingEvent::before {
  background-color: #8CD9E0;
  border-radius: 50%;
  width: 6px;
  height: 6px
}

.CmaEventName::before {
  background-color: #05f07aab;
  border-radius: 50%;
  width: 6px;
  height: 6px
}

.otherevent::before {
  background-color: #78787c;
  border-radius: 50%;
  width: 6px;
  height: 6px
}

.buyerEvent .rbc-event-content,
.ListingEvent .rbc-event-content,
.CmaEventName .rbc-event-content,
.CmaEventName .rbc-event-content,
.CmaEventName .rbc-event-content,
.MeetingEvent .rbc-event-content,
.otherevent .rbc-event-content {
  margin-left: 10px;
  font-size: 14px;
  font-family: "GillSansMedium", sans-serif;
}

.rbc-date-cell.rbc-now button {
  width: 35px;
  height: 35px;
  background-color: #667DD1;
  border-radius: 50%;
  color: #fff;
  font-weight: 400;
}

.rbc-date-cell,
.rbc-header span {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 0 !important;
  text-align: center !important;
  color: #70747b !important;
  font-size: 1rem;
  font-weight: 400 !important;
}

.myCustomHeight {
  /* position: relative; */
  overflow: auto;
}

.rbc-month-row+.rbc-month-row {
  border-top: 1px solid #d5d7e3 !important;
  overflow: unset !important;
}

.rbc-day-bg+.rbc-day-bg {
  border-left: 1px solid #d5d7e3 !important;
}

.rbc-month-view,
.rbc-time-view {
  border: 1px solid #d5d7e3 !important;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.rbc-header {
  border-bottom: none !important;
}

.rbc-time-header-content {
  border-left: 1px solid #d5d7e3 !important;
}

/* .rbc-time-content::-webkit-scrollbar {
  width: 0 !important;
} */

.rbc-time-content>*+*>* {
  border-left: 1px solid #d5d7e3 !important;
}

.rbc-timeslot-group {
  /* border-bottom: 1px solid #d5d7e3 !important; */
  border: none !important;
}

.rbc-time-header.rbc-overflowing,
.rbc-time-view,
.rbc-time-header-content {
  border: none !important;
}

.addEventButton {
  position: absolute;
  bottom: 60px;
  z-index: 9;
  right: 60px;
  transition: 0.1s ease-in-out;
}

.plusIconImg {
  width: 50px;
  height: 50px;
  background: #667DD1;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.plusIconImg:hover {
  background-color: #8796d2;
}

.rbc-toolbar-label {
  font-size: 1.5rem;
  font-weight: 500;
}

.rbc-toolbar button {
  padding: 0.3rem 1rem !important;
  font-size: 0.8rem !important;
  box-shadow: 0px 1px 4px #1522321a !important;
  border: 1px solid #667DD1 !important;
  border-radius: 5px !important;
  color: #667DD1 !important;
  line-height: inherit !important;
}

.rbc-toolbar button:nth-child(2) {
  margin-right: 0.8rem !important;
  margin-left: 0.8rem !important;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  background-color: #667DD1 !important;
  color: #fff !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-color: #667DD1 !important;
  color: #fff !important;
}

.rbc-toolbar button:hover,
.rbc-toolbar button:focus {
  background: none !important;
}

.css-5wzqtd-singleValue:hover {
  color: white !important;
}

.kXPGqK .blockbuilder-branding {
  visibility: hidden !important;
}

.css-5wzqtd-singleValue {
  font-family: "GillSansMedium" !important;
  color: #70747b !important;
}

.cursor {
  cursor: pointer;
}

.emailtemplatecursor {
  cursor: pointer !important;
}

/* checkbox css start */

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  /* -webkit-appearance: none; */
  background-color: transparent;
  border: 1px solid #667DD1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 3px;
  margin-right: 5px;
}

.form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 6px;
  width: 4px;
  height: 8px;
  border: 1px solid #667DD1;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.logtextmeeting {
  font-family: "GillSansMedium" !important;
  font-size: 0.9rem !important;
  color: #131523 !important;
}

/* .crateemailindicator .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.crateemailindicator .css-12a83d4-MultiValueRemove:hover {
  background-color: unset !important;
}
.crateemailindicator .css-12a83d4-MultiValueRemove {
  color: #70747b;
}
.crateemailindicator .css-wsp0cs-MultiValueGeneric {
  color: #70747b;
} */

/* checkbox css end */

.custom-calendar-input {
  background: url("../images/calendar.svg") no-repeat 15px center;
  background-size: 20px;
  padding: 0.5rem 2rem;
  font-family: "GillSansLight" !important;
  font-size: 14px;
  color: #131523 !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 4px;
  width: 100%;
}


.custom-calendar-inputs {
  background: url("../images/calendar.svg") no-repeat 15px center;
  background-size: 20px;
  padding: 0.5rem 2rem;
  font-family: "GillSansLight" !important;
  font-size: 14px;
  color: #131523 !important;
  /* background-color: #fff; */
  border-radius: 4px !important;
  width: 100%;
}

.custom-calendar-input:hover {
  background: url("../images/headericon/whitecalendar.svg") no-repeat 15px center;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #667DD1;
}

.custom-calendar-inputlissting {
  background: url("../images/calendar.svg") no-repeat 15px center;
  background-size: 20px;
  padding: 0.5rem 2rem;
  font-family: "GillSansLight" !important;
  font-size: 14px;
  color: #131523 !important;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #fff;
}

.apartmonthview .min_w_custom_calendar {
  min-width: none !important;

}

/*     background-color: #f5f5f5 !important;
    border: 1px solid #f5f5f5 !important; */

.custom-calendar-inputlisstingsecond {
  background: url("../images/calendar.svg") no-repeat 15px center;
  background-size: 20px;
  padding: 0.5rem 2rem;
  font-family: "GillSansLight" !important;
  font-size: 14px;
  color: #131523 !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 4px;
  width: 100%;

}

.custom-calendar-inputlisstingsecond .react-datepicker-popper[data-placement^=bottom] .react-datepicker__time-container .react-datepicker__time {
  width: "10vw" !important;
}

.custom-calendar-inputs:hover {
  border-radius: 4px !important;
  background: url("../images/headericon/whitecalendar.svg") no-repeat 15px center;
  color: #fff !important;
  background-color: #667DD1;
}

.custom-calendar-inputlissting:focus {
  border: 1px solid #667DD1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.buyingpreferenceaccordion .MuiAccordionSummary-expandIconWrapper {
  margin-top: 0rem !important;

}

.subheadingheader {

  font-size: 1rem !important;
  font-family: "GillSansMedium" !important;
  text-transform: none;
  line-height: 0;
  letter-spacing: 0;
  min-width: 0;
  box-shadow: none !important;
  text-shadow: none !important;
  background-color: none !important;
  text-transform: uppercase !important;
}

.custom-calendar-inputlisstingsecond:focus {
  border: 1px solid #667DD1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.assigned {
  text-align: right;
  display: flex;
}


.custom-calendar-inputs:focus {
  border-radius: 4px !important;
  border: 1px solid #667DD1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.custom-calendar-input:focus {
  border-radius: 4px !important;
  border: 1px solid #667DD1 !important;
  box-shadow: none !important;
  outline: none !important;
}

.onlyalarmstyleicon {
  background: url("../images/tasks/Alaram.svg") no-repeat 15px center;
  background-size: 20px;
  font-family: "GillSansLight" !important;
  font-size: 14px;
  color: #131523 !important;
  background-color: #f5f5f5;
  border-radius: 4px;
  width: 9vw !important;
  padding: 0.5rem 2.3rem;
}

.noCalendarIcon {
  z-index: 9999;
  background: url("../images/home/dropdownicon.svg") no-repeat calc(100% - 15px) center;
  background-size: 20px;
  font-family: "GillSansLight" !important;
  font-size: 14px;
  color: #131523 !important;
  /* background-color: #f5f5f5; */
  border-radius: 4px;
  width: 100%;
  padding: 0.5rem 1rem;
}

.noCalendarIcon:hover {
  background: url("../images/home/dropdowniconwhite.svg") no-repeat calc(100% - 15px) center;
  color: #fff !important;
  background-color: #667DD1;
}

.onlyalarmstyleicon:hover {
  background: url("../images/tasks/whiteAlaram.svg") no-repeat 15px center;
  color: #fff !important;
  background-color: #667DD1;
}

.react-datepicker__input-container {
  background-color: #fff;
  border-radius: 4px !important;
}

.react-datepicker__input-container:hover {
  color: #fff !important;
  background-color: #667DD1;
  border-radius: 4px !important;
}

.react-datepicker__input-container:hover>input {
  background: url("../images/headericon/whitecalendar.svg") no-repeat 15px center !important;
  color: #fff !important;
}

.react-datepicker__input-container:hover>.noCalendarIcon {
  background: url("../images/home/dropdowniconwhite.svg") no-repeat calc(100% - 15px) center !important;
}

.react-datepicker__input-container:hover>.AddContactCalendarDob {
  background-image: unset !important;
}

/* .custom-calendar-input:focus-visible {
  border-bottom-color: #b51148;
  outline: none;
} */
.customtimeClass,
.react-datepicker__time-box,
.react-datepicker__time-container,
.react-datepicker--time-only {
  width: 100% !important;
}

.react-datepicker__day-name,
.react-datepicker,
.react-datepicker__day,
.react-datepicker__time-name {
  font-family: "GillSansLight" !important;
  font-size: 14px !important;
  color: #131523 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-family: "GillSansMedium", sans-serif !important;
  font-size: 1rem !important;
  color: #131523 !important;
  font-weight: 400 !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #667DD1 !important;
  border-radius: 50%;
  font-family: "GillSansLight" !important;
  font-weight: unset !important;
  color: #fff !important;
}

.react-datepicker {
  border: 1px solid #e6e6e9 !important;
}

.react-datepicker__header--time--only,
.react-datepicker__triangle {
  display: none;
}

/* .react-datepicker__day--today{
  background-color: #6a7abc !important;
  border-radius: 50%;
  font-family: "GillSansLight" !important;
  font-weight: unset !important;
  color: #fff !important;
} */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #667DD1 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #6a7abc !important;
  font-family: "GillSansLight" !important;
  font-weight: unset !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #667DD1 !important;
  border-radius: 50% !important;
  margin: 0.066rem;
  color: #fff !important;
  font-family: "GillSansLight" !important;
  font-size: 14px !important;
}

.taskdetail .rdw-editor-wrapper {
  background-color: #f6f6f7;
  padding: 0.5rem;
  margin-left: 0rem;
  margin-right: 0rem;
  border-radius: 10px;
}

.taskdetail .rdw-editor-main {
  padding-bottom: 2rem !important;
}

.taskdetail .rdw-editor-toolbar {
  justify-content: flex-end;
  background: none;
}

.preview .rdw-editor-main {
  padding-bottom: 0rem !important;
}

.taskdetail .rdw-editor-main {
  padding-bottom: -1rem !important;
}

.preview .rdw-editor-wrapper {
  /* border-bottom: 1px solid #e6e6e9; */
  margin-right: 0rem;
  margin-left: 0rem;
  padding: 1rem;
}

.emailmettingbodyareanew .public-DraftEditor-content[contenteditable=true] {

  max-height: calc(100vh - (36vh + 197.36px)) !important;
  overflow: scroll;
}


/* .custom-datepicker-class .react-datepicker__today-button {
  color: #b51148;
  background-color: white;
  border-top: 0px;
  text-align: right;
  margin-right: 20px;
} */
.css-1a718in-control:hover .select-placeholder-text,
.css-1a718in-control:focus .select-placeholder-text {
  color: #fff !important;
}

.MuiBox-root:focus-visible {
  outline: none !important;
}

.custom-radio-wrap .sequence {
  margin-bottom: 10px;
}

.custom-radio-wrap .sequence:last-child {
  margin-bottom: 0;
}

.custom-radio-wrap .sequence label {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 5px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

.custom-radio-wrap .sequence .label-text {
  vertical-align: middle;
  font-family: "GillSansMedium";
  cursor: pointer;
  color: #70747b;
  font-size: 0.9rem;
  padding-left: 10px;
  margin-left: -5px;
}

.custom-radio-wrap .sequence input {
  display: none;
  cursor: pointer;
}

.custom-radio-wrap .sequence input:checked+label {
  background-color: #e9ecee;
  color: #99a1a7;
  border: 1px solid #667DD1;
}

.custom-radio-wrap .sequence input:checked+label {
  background-color: #e9ecee;
  color: #99a1a7;
  border: 1px solid #6a7abc;
}

.custom-radio-wrap .sequence input:checked~.label-text {
  color: #70747b;
  font-family: "GillSansMedium";
  font-size: 0.9rem;
}

.custom-radio-wrap .sequence input:checked+label:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  position: absolute;
  top: 1px;
  left: 1px;
  background: #667DD1;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-shadow: none;
  font-size: 32px;
}

.custom-radio-wrap .sequence input:checked+label:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50px;
  position: absolute;
  top: 1px;
  left: 1px;
  background: #6a7abc;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-shadow: none;
  font-size: 32px;
}

.toolbarClassNameCustom {
  display: none !important;
}

.editorClassNameSequence {
  padding-bottom: 0 !important;
}

.wrapperClassNameSequence {
  margin: 0 !important;
}

.sequenceeditDeleteModal {
  min-width: 145px;
}

/* calendar end */

/* Erro Modal Email */
.CloseModalErrorEmial {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.ErrorMsgEmailMain {
  padding: 1rem;
}

.ErrorMsgEmailBtn {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1rem;
}

.ErrorMsgEmailHeeader h3 {
  font-size: 1rem;
  color: #131523;
  font-weight: 500;
}

.ErrorMsgEmail p {
  color: #70747b;
  font-size: 1rem;
}

/* Erro Modal Email */

/* react multi Email Class start */
.contactpropertiesonlytabsfor .css-19kzrtu {
  padding: 0px !important;
}

.reactMultiEmailClass {
  border: none !important;
}

.reactMultiEmailClass input,
.react-multi-email [data-tag] {
  font-size: 1rem !important;
  color: #131523 !important;
  font-weight: 500 !important;
}

.react-multi-email.empty>span[data-placeholder] {
  color: #70747b !important;
  font-size: 0.9rem;
}

/* react multi Email Class end */

/* notification start */
.notificationHeader {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e9;
}

.ErrorMsgEmailnotificationHeader h3 {
  color: #171725;
  letter-spacing: 0.1px;
  font-size: 1.5rem;
}

.thisMonthData h4 {
  color: #70747b;
  text-transform: capitalize;
  font-size: 0.875rem;
  padding: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.notificationInboxData {
  padding: 0.5rem;
  padding-top: 0;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e6e6e9;
  padding-bottom: 1rem;
  padding-top: 1rem;
  cursor: default !important;
}

.noBorderLastChild:last-child {
  border-bottom: none;
}

.notificationInboxData:first-child {
  margin-top: 0 !important;
}

.heightdefined {
  height: 41vh;
}



.h_w_3p5imp {
  height: 3.5rem !important;
  width: 3.5rem !important;
  min-height: 3.5rem !important;
  min-width: 3.5rem !important;
}

.notificationInboxData:hover {
  /* background-color: #ababfa; */
  cursor: pointer;
}

.inboxCalendarAndDots {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificationNameMain {
  display: flex;
  align-items: flex-start;
}

.shortNameWidth {
  width: 50px;
}

.notificationsortname {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaeeff;
}

.notificationsortname p {
  color: #131523;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}

.margin_right_0px_imp {
  margin-right: 0px !important;
}

.margin_right_0p1rmimp {
  margin-right: 0.1rem !important;
}

.margin_left_10px_imp {
  margin-left: 10px !important;
}

.padding_left_none_imp {
  padding-left: 0px !important;
}

.notificationInboxName {
  /* margin-left: 0.5rem; */
  font-size: 0.875rem;
  color: #70747b;
}

.notificationInboxName .name {
  color: #6a7abc;
}

.notificationInboxName .address {
  color: #131523;
}

.NotificationTimeInbox {
  display: flex;
  align-items: center;
}

.NotificationTimeInbox p {
  color: #70747b;
  font-size: 0.875rem;
}

.inboxActionBtns {
  display: flex;
  align-items: center;
}

.inboxActionBtns {
  margin-top: 1rem;
}

.sendtext .rdw-editor-wrapper {
  padding-left: 1rem;
}

.buttonsNoBg {
  border-radius: 5px;
  border: 1px solid #6a7abc;
  padding: 5px 1.5rem;
  color: #6a7abc;
  font-family: "GillSansMedium";
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnsPadding {
  padding: 5px 1.5rem !important;
}

.readingTimesInbox p {
  color: #70737b;
  font-size: 12px;
  margin-top: 1rem;
}

.inboxMsgThreeDotsBox {
  position: absolute;
  right: 7px;
  top: 28px;
  min-width: 130px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0.5rem;
  padding-top: 0;
  z-index: 99;
  background-color: #fff;
}

.inboxMsgThreeDotData p {
  color: #131523;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem;
}

.InboxContentMain {
  overflow: auto;
  height: 50vh;
}

.InboxContentMain::-webkit-scrollbar {
  width: 0px;
}

.inboxComPadding .MuiBox-root {
  padding-bottom: 0 !important;
}

.css-175ew4b-indicatorContainer {
  z-index: 1 !important;
}

.MuiBox-root {
  z-index: 9;
}

.zIndex9999 {
  z-index: 9999;
}

.isShowModalTaskEd {
  display: none;
}

.ShowOnHoverModal:hover .isShowModalTaskEd {
  display: unset;
}

/* notification end */

.completeBtnStyle {
  border: 1px solid #21d59b;
  border-radius: 2px;
  background-color: #e8fcf5;
  color: #21d59b;
  padding: 0.2rem 1rem;
}

.CompleteTaskWidth {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disableBtnStyle {
  background: #e6e6e9 !important;
  color: #70747b !important;
}

.weightimp {
  width: 8% !important;
}

.archieveddesing .css-i4bv87-MuiSvgIcon-root {
  margin-top: 0 !important;
  color: #4c5164;
  width: 0.9em !important;
  height: 0.9em !important;
}

.archieveddesing2 .css-i4bv87-MuiSvgIcon-root {
  margin-top: 0 !important;
  color: #667DD1;
  width: 0.9em !important;
  height: 0.9em !important;
}

.swiperPropertyImage {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
}

.transactionImageSliderModalShadow .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.active a .navIcon h3 {
  color: #fff;
}

/* .mobileActiveNav {
  padding-left: 0 !important;
} */

/* navanimation start */
.forAnimationFadein {
  opacity: 0;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-name: fadeInBottom;
}

.forAnimationFadein:nth-child(2) {
  animation-delay: 0.1s;
}

.forAnimationFadein:nth-child(3) {
  animation-delay: 0.2s;
}

.forAnimationFadein:nth-child(4) {
  animation-delay: 0.3s;
}

.forAnimationFadein:nth-child(5) {
  animation-delay: 0.4s;
}

.forAnimationFadein:nth-child(6) {
  animation-delay: 0.5s;
}


.transform15deg {
  transform: rotate(15deg);
}

.transform15degimp {
  transform: rotate(15deg);
}

.forAnimationFadein:nth-child(7) {
  animation-delay: 0.6s;
}

.forAnimationFadein:nth-child(8) {
  animation-delay: 0.7s;
}

.forAnimationFadein:nth-child(9) {
  animation-delay: 0.8s;
}

.forAnimationFadein:nth-child(10) {
  animation-delay: 0.9s;
}

.forAnimationFadein:nth-child(11) {
  animation-delay: 1ms;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
  }
}

.MuiPaper-root:before {
  background: none !important;
}

.table-theme-1 thead tr {
  border: none !important;
}

.contactsearchbartext {
  position: absolute;
  left: 12px;
}

.contactsearchiconofrext {
  border: 1px solid #f5f5f5;
  color: #70747b;
  font-family: "GillSansMedium";
  font-size: 0.9rem;
  background-color: #f5f5f5;
  border-radius: 40px;
  padding: 7px;
  padding-left: 42px;
  /* padding-right: 137px; */
}

.contactsearchiconofrext:focus,
.contactsearchiconofrext:active,
.contactsearchiconofrext:focus-visible {
  outline: none !important;
  border: 1px solid #667DD1 !important;
}

.threedotsbgcolor img {
  height: 12px;
}

.deletesbgcolor {
  cursor: pointer;
  background-color: #f5f7fb;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.threedotsbgcolor {
  cursor: pointer;
  background-color: #f5f5f5;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.backgrounShadowBody .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.createTaskmodal .public-DraftStyleDefault-block {
  margin: 1em 1em !important;
  /* min-height: 100px; */
}


.w-50pi {
  width: 50% !important;
}

.threedotsiconclickfunction {
  position: absolute;
  right: 10px;
  width: 188px;
  z-index: 1000;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}

.threedotsiconclickfunction2nd {
  position: absolute;
  top: -78px;
  right: 10px;
  width: 185px;
  z-index: 1000;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}

.editIconMain {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  cursor: pointer;
}

.editIconMain h3 {
  width: 100%;
  font-size: 0.9rem;
  color: #131523;
  margin-bottom: 0;
  margin-left: 8px;
  text-transform: capitalize;
}

.droponlyheader {
  padding-top: 5px !important;
}

.contactpreviewsimpleaccordian .MuiPaper-elevation {
  margin-top: 1rem !important;
}

.select__multi-value {
  background-color: #e6e6e9 !important;
}

.select__multi-value__remove {
  background-color: unset !important;
  color: unset !important;
}

.select__multi-value__label {
  color: #131523 !important;
  font-size: 14px !important;
}

.css-1xc3v61-indicatorContainer {
  color: #70747b !important;
}

/* .select__multi-value__label:hover{
  color: #fff !important;
} */
/* .select__input-container:hover{
    color: #fff !important;
}  */
/* .select__control:hover{
  color: #fff !important;
} */
.react-datepicker__header {
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #e6e6e9 !important;
}

.smstemplatelibrary .rdw-editor-main {
  padding-bottom: 0rem !important;
}

.schedulemeeting {
  border-bottom: none !important;
}

.attendeedescription .rdw-editor-wrapper {
  margin-left: 0rem;
  margin-right: 0rem;
}

.contactquickviewzindex {
  z-index: 999;
}

.AddlistingImages .dWLNfR {
  justify-content: center !important;
}

.css-hlgwow {
  padding: 0px !important;
}


.blockbuilder-branding {
  visibility: hidden !important;
}

.bootstrap a {
  visibility: hidden;
}

.css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.rdw-editor-main {
  overflow: unset !important;
}

.NotificationTimeInbox img {
  height: 18px;
  margin-right: 5px;
}

.taskdetail .rdw-image-modal {
  position: absolute;

  left: -195px !important;
}

.taskdetail .rdw-link-modal {
  position: absolute;

  left: -162px !important;
  height: 227px !important;
}

.rdw-link-modal {
  height: 225px !important;
  top: -245px !important;
}

.meeting .rdw-link-modal {

  left: -175px !important;
  height: 225px !important;
}

.rdw-image-modal {
  top: -245px !important;
}

.createemaileditor .rdw-image-modal {
  top: -263px !important;
}

.createemaileditorsecond .rdw-image-modal {
  top: -20ch !important;
}

.createemaileditor .rdw-link-modal {
  top: -263px !important;
}

.createemaileditorsecond .rdw-link-modal {
  top: -263px !important;
}

.createemaileditor .rdw-colorpicker-modal {
  top: -219px !important;
}

.createemaileditorsecond .rdw-colorpicker-modal {
  top: auto !important;
  left: -25px !important;
}

.rdw-emoji-modal {
  top: auto !important;
  left: -118px !important;
}

.noteaddmodal .rdw-image-modal {
  left: -106px !important;
}

.noteaddmodal .rdw-link-modal {
  left: -101px !important;
  height: 225px !important;
}

.loeemail .rdw-image-modal {
  top: 35px !important;
  left: -205px !important;
}

.createemaileditor .rdw-image-modal {
  left: -108px !important;
}

.createemaileditorsecond .rdw-image-modal {
  left: -20ch !important;
}

.callingmodal .rdw-image-modal {
  left: -213px !important;
}

.schedulemeeting .rdw-image-modal {
  left: -207px !important;
}

.attendeedescription .rdw-image-modal {
  left: -126px !important;
}

.logtext .rdw-image-modal {
  left: -198px !important;
}

.phonecall .rdw-image-modal {
  left: -206px !important;
}

.logmeeting .rdw-image-modal {
  left: -198px !important;
}

.rdw-image-modal {
  left: -207px !important;
}

.createemaileditor .rdw-fontfamily-optionwrapper {
  top: -182px !important;
}

.createemaileditorsecond .rdw-fontfamily-optionwrapper {
  top: auto !important;
}


.marginLeftTab {
  margin-left: 1.8rem !important;
}

.smstemplatelibrary .rdw-editor-wrapper {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.preview .rdw-image-modal {
  left: -130px !important;
}

.emailtemplates {
  padding: 8px !important;
}

.imagelogo img,
.imagelogos img {
  height: 24px;
}

.imagelogo {
  display: flex;
  justify-content: center;
}

.logemaildescription .public-DraftStyleDefault-block {
  margin: 1em !important;
  /* min-height: 100px; */
}

/* .css-yyaowb-control {
  margin: 2px 5px  !important;
  padding: none !important;
   } */

/* .public-DraftStyleDefault-block {
  min-height: 100px;
} */


.padding_p5remimp {
  padding-left: 0.8rem !important;
}

.contactpreviewscrollbar .MuiAccordionSummary-content {
  margin: 0px 0 !important;
  overflow: auto;
}

.padding_15pximp {
  padding: 15px !important;
}

.formatted_color_imp {
  color: #667DD1 !important;
}

.contact_preview_design_title {
  max-width: 15vw;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #70747b;
  font-size: 1rem;
  padding-left: 0.5rem;
  margin-right: 0.5rem;
}

/* 
.noteaddmodal .rdw-editor-wrapper,
.noteaddmodal,
.noteaddmodalMain {
  height: 100% !important;
} */


.padding_0px_imp {
  padding: 0px !important;
}

.react-multi-email>span[data-placeholder] {
  padding: 0 !important;
}

.react-multi-email {
  padding: 0 !important;
}


.react-select-10-placeholder:hover {
  color: white;
}

.backdroppForHeaderToggle {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

/* navanimation end */

/* lottie start */
.lottieWithText {
  width: 495px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.lottieWithText p {
  letter-spacing: 0.1px;
  color: #131523;
  text-transform: capitalize;
}

.lottieWithText p span {
  color: #667DD1 !important;
  margin-right: 3px;
}

.css-eblzha-control {
  cursor: pointer !important;
}


.MuiAutocomplete-popupIndicator,
.MuiAutocomplete-endAdornment {
  display: none !important;
}

.uiAutocomplete-inputRoot {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}


.css-1h0yxl0-singleValue {
  cursor: pointer !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.autocompletestyleaddlsiting .MuiAutocomplete-inputFocused {
  background-color: #fff !important;
  border-radius: 4px !important;
  padding: 0.5rem 12px !important;
  width: 100% !important;
  border: 1px solid #fff;
  color: #70747b !important;
}

.AutoCompleteStyle .MuiAutocomplete-inputFocused {
  border: 1px solid #f5f5f5 !important;
  background-color: #f5f5f5 !important;
  border-radius: 4px !important;
  padding: 0.6rem 12px !important;
  width: 100% !important;
  color: #70747b !important;
  font-weight: 300 !important;
}


.autocompletestyleaddlsiting .MuiAutocomplete-inputFocused:focus,
.autocompletestyleaddlsiting .MuiAutocomplete-inputFocused:active,
.autocompletestyleaddlsiting .MuiAutocomplete-inputFocused:focus,
.autocompletestyleaddlsiting .MuiAutocomplete-inputFocused:active {
  border: 1px solid #667DD1 !important;
  border-radius: 4px !important;
}

.AutoCompleteStyle .MuiAutocomplete-inputFocused:focus,
.AutoCompleteStyle .MuiAutocomplete-inputFocused:active,
.AutoCompleteStyleContact .MuiAutocomplete-inputFocused:focus,
.AutoCompleteStyleContact .MuiAutocomplete-inputFocused:active {
  border: 1px solid #667DD1 !important;
  border-radius: 4px !important;
}

.AutoCompleteStylegrey .MuiAutocomplete-inputFocused:focus,
.AutoCompleteStylegrey .MuiAutocomplete-inputFocused:active,
.AutoCompleteStylegrey .MuiAutocomplete-inputFocused:focus,
.AutoCompleteStylegrey .MuiAutocomplete-inputFocused:active {
  border: 1px solid #667DD1 !important;
  border-radius: 4px;
}

.AutoCompleteStylegrey {
  border: 1px solid #f5f5f5 !important;
  background-color: #f5f5f5;
  border-radius: 4px;
  width: 100%;
  color: #70747b !important;
  font-family: "GillSansMedium";
  font-size: 0.9rem;
}

.AutoCompleteStyleContact {
  background-color: #ffff;
  border-radius: 4px;
  width: 100%;
  color: #70747b !important;
  font-family: "GillSansMedium";
  font-size: 0.9rem;
}

.AutoCompleteStyleContact input {
  color: #70747b !important;
  font-family: "GillSansMedium" !important;
  font-size: 0.9rem !important;
  text-transform: capitalize;
}

.AutoCompleteStylegrey input {
  color: #70747b !important;
  text-transform: capitalize;
  font-family: "GillSansMedium" !important;
  font-size: 0.9rem !important;
}



.MuiAutocomplete-inputRoot {
  padding: 0 !important;
  margin: 0 !important;
}

.css-gdh49b-MuiAutocomplete-listbox {
  padding: 0px !important;
}


.MuiAutocomplete-popper {
  background-color: #fff !important;
  border: 1px solid #E6E6E9;
  padding: 0 !important;
  margin: 0 !important;
}

.MuiAutocomplete-input {
  color: #70747b;
  font-size: 0.8rem !important;
}

.MuiAutocomplete-input::placeholder {
  color: #70747b;
}

.MuiAutocomplete-option {
  color: #70747B;
  background: transparent !important;
  border-bottom: 1px solid #E6E6E9;
  font-family: "GillSansMedium";
  margin: 2px !important;
  font-size: 0.8rem !important;

}

.MuiAutocomplete-option:hover {
  background: #667DD1 !important;
  color: #fff !important;
}

.MuiAutocomplete-paper {
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* lottie end */

/* loader */
.loaderAll {
  border: 5px solid #8796d2;
  border-top: 5px solid #eaeeff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: loader 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12121;
}

.loderdesing {
  border: 5px solid #8796d2;
  border-top: 5px solid #eaeeff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: loader 1s linear infinite;
  transform: translate(-50%, -50%);
  z-index: 12121;
}

.buttonLoaderAll {
  border: 3px solid #fff;
  border-top: 3px solid #70747b;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: loaderBtn 1s linear infinite;
  margin-right: 0.4rem;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loaderBtn {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
}

.AddContactCalendarDob,
.AddContactCalendarDob:hover {
  background: #fff !important;
  width: 100% !important;
  color: #70747b !important;
  padding: 0.5rem !important;
}

.captaltabletext {
  text-transform: capitalize;
}

.react-datepicker__close-icon::after {
  background-color: unset !important;
  color: #70747b !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.react-datepicker__input-container:hover>.react-datepicker__close-icon::after {
  color: #ffff !important;
}

/* loader */

/* loader */
.rbc-show-more {
  /* font-family: "GillSansMedium"; */
  text-transform: capitalize;
  font-size: 13px !important;
  margin-left: 15px !important;
  color: #667DD1 !important;
}

.react-multi-email>input {
  padding: 0 !important
}

.minWidthTick {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center
}

.timezoneforaddproperty {
  min-height: 50px !important;
}

.ShowAndHideEandD {
  display: none;
}

.OnHoverShowDivEandD:hover>.ShowAndHideEandD {
  display: block;
}

.TransactionImgSliderBg {
  width: 50vw;
  height: 34vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.TransactionImgSliderBgofannouncement {
  min-width: 50vw;
  max-width: 50vw;
  min-height: 34vw;
  max-height: 34vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.RemoveTablePadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.edituserphoneinput .PhoneInputInput {
  background-color: #f5f5f5 !important;
}

.leftn6imp {
  left: -6px !important;
}

.border_top_vcolor {
  border-top: 2px solid #667DD1;
}

.GoalComBorder {
  border-bottom: 1px solid #e6e6e9;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}

/* .Toastify__progress-bar-theme--light {
  background-color: #667DD1 !important;
} */
.expandMoreIconClick {
  transform: rotate(-90deg) !important;
}

.subheadigdata {
  padding-left: 0.5rem;
  color: #70747b;
  font-family: "GillSansMedium", sans-serif;
}

.rbc-timeslot-group>.rbc-time-slot:last-child {
  border-top: none !important;
}

.MuiTooltip-tooltip {
  font-size: 0.9rem !important;
  font-family: "GillSansMedium" !important;
  background-color: #667DD1 !important;
  color: #fff !important;
}

.invalid_feedback_error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #f0142f;
  font-family: "GillSansMedium";
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #ccc !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none !important;
  border-left: 2px solid #667dd1 !important;
  min-height: 35px !important;
}

.rbc-day-slot .rbc-event-label {
  margin-left: 8px !important;
}

/* .rbc-time-header.rbc-overflowing {
  margin-right: unset !important;
} */

.iconsdesignocontact {
  text-transform: uppercase;
  color: #131523;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #e1e8fd;
}

/* otp */
.otpSection input {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 4px;
  margin: 0 0.6rem;
  border: 1px solid #e6e6e9;
  font-size: 1rem;
  font-family: "GillSansMedium" !important;
}

.otpSection input:focus {
  outline: none;
}

input::placeholder {
  text-transform: capitalize !important;
}

.css-wsp0cs-MultiValueGeneric {
  font-family: "GillSansMedium" !important;
  font-size: 0.9rem !important;
  color: #131523 !important;
}

.calenderFilterMain {
  width: 400px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.calenderFilterMain h4 {
  color: #131523;
  font-size: 1rem;
  text-transform: uppercase;
}

.calenderFilterStyle {
  width: 228px;
  margin-left: 0.5rem;
}

/* otp */

/* #react-select-3-input{
  color: #fff !important;
} */
.winWidth180 {
  min-width: 180px;
}

.propertyoverviewformobileandlaptop {
  padding-bottom: 0.7rem;
}

.tableHeaderTextSort {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sortDownIcon img {
  cursor: pointer;
}

.sortDownIcon img:last-child {
  margin-top: 5px;
}

.onlylogwidthmodal {
  width: 272px !important;
}

#printsummary {
  display: none;
  visibility: hidden;
}

@media print {
  @page {
    /* size: landscape; */
    margin: 0;
  }

  body,
  html {
    visibility: hidden;
    -webkit-print-color-adjust: exact !important;
  }

  .avoidpagebreak {
    break-inside: avoid;
  }

  .printThisDiv {
    background-color: #ffff !important;
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }

  #printsummary {
    background-color: #ffff !important;
    visibility: visible;
    position: absolute;
    display: unset;
    left: 0;
    top: 0;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;
    /* Firefox 48 – 96 */
    print-color-adjust: exact !important;
    /* Firefox 97+, Safari 15.4+ */
  }

  #printDetails {
    visibility: visible;
    position: absolute;
    display: unset;
    left: 0;
    top: 0;
  }


}

.minWidthScroll1400Task {
  min-width: 1400px;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  border-radius: 15px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: #A3AED6;
  border-radius: 15px;
}

/* .closeonoutofDiv {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  z-index: 999;
} */

.jWkLDY {
  justify-content: center !important;
}

.forTwoStepLabel {
  font-size: 0.9rem;
}

.SVGStyleAll {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}


.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -15px;
  top: 4px !important;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #ffff !important;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #e6e6e9 !important;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #667DD1 !important;

  color: #fff !important;
}

.jWkLDY .file-types {
  display: none !important;
}

.svgiconidentifier {
  fill: #667DD1;
}

.svgstrokewidht {
  stroke-width: 0px;
}

.svgiconidentifier2 {
  fill: #ffffff;
}

.strokeidentifier {
  stroke: #FFFFFF;
}

.participant_defineclass {
  color: #fff !important;
}


.hovermanager:hover .svgiconidentifier {
  fill: #fff !important;
}

.hovermanager:hover .svgiconidentifier2 {
  fill: #667DD1 !important;
}

.hovermanager:hover .strokeidentifier {
  stroke: #667DD1 !important;
}

#rangeValue {
  position: relative;
  display: block;
  text-align: center;
  font-size: 6em;
  color: #999;

  font-weight: 400;
}

.range {
  width: 100%;
  height: 10px;
  -webkit-appearance: none;
  background: #fff;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: inset 0 0 5px #fff;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #667DD1;
  cursor: pointer;
  border: 4px solid #667DD1;
  box-shadow: -407px 0 0 400px #e6e6e9;
}

/* .filedropzonestyle {
  border-radius: 4px;
  border: 1px dotted #667DD1;
  padding: 4rem;
} */


.ml_2pximp {
  margin-left: 2px !important;
}

.filedropzonestyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70747b;
  font-size: 1rem;
  cursor: pointer;
  padding: 4rem !important;
}

.listdropselect {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #70747b;
  font-size: 1rem;
}

.icons_menu_icon {
  margin-top: 0px !important;
  color: #667DD1 !important;
  width: 1.4em;
  height: 1.4em;
}

.menu_bar_drop_down_desing_displaydesing {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;

}

.menu_bar_uppper_div_desing {
  text-align: left;
  left: 107px;
}

.menu_bar_drop_down_desing {
  min-width: 27vw;
  right: 28px;
  padding: 6px;
  top: -8px;
}

.participant_defineclass {
  margin-top: 0px !important;
  color: #667DD1 !important;
}

.border_none_imp {
  border: none !important;
}

.commom_border_imp {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}


.bg_own_imp2 {
  background-color: #eaeeff !important;
}

.react-datepicker__close-icon {
  margin-right: 8% !important;
}

.display_block_imp {
  display: block !important;
}

.participant_defineclass_2 {
  margin-top: 0px !important;
  color: #667DD1 !important;
}

.participant_defineclass:hover {
  color: #fff !important;
}

.Editorofsignature {
  width: 50vw !important;
}

.filedropzonestyle p span {
  font-size: 1rem;
  color: #667DD1;
  border-bottom: 1px solid #667DD1;
}

.createemaileditor .public-DraftStyleDefault-block {
  margin: 0em 0 !important;
}


.createemaileditorsecond .public-DraftStyleDefault-block {
  margin: 0em 0 !important;
}



.createemaileditor .rdw-editor-main {

  padding-bottom: 0.5rem !important;
  background-color: white;
}

.createemaileditorsecond .rdw-editor-main {

  padding-bottom: 0.5rem !important;
  background-color: white;
}

.createemaileditor .rdw-editor-main {

  min-height: 256px !important;
  overflow: auto !important;
}

.createemaileditorsecond .rdw-editor-main {
  /* max-height: 312px;
  min-height: 312px !important;
  overflow: auto !important; */
}

.participants_middle {
  vertical-align: middle;
  line-height: 24px;
}


.newsmstemplatemaxheign80vh {
  padding: 1.5rem;
  max-height: 80vh !important;
  overflow: auto;
  padding-top: 0rem !important;
}

.newsmstemplaemaxheight2nd {
  padding: 1.5rem;
  height: calc(100vh - (3vh + 197.36px)) !important;
  overflow: auto;
  padding-top: 0rem !important;

}

.readMoreText {
  width: 64px;
  border-bottom: 1px solid #667DD1;
  color: #667DD1;
  cursor: pointer;
}

.readMoreTextbutton {
  width: 66px;
  border-bottom: 1px solid #667DD1;
  color: #667DD1;
  cursor: pointer;
  margin-left: 1rem;
}

.viewUploadedImages {
  margin-top: 1rem;
  position: relative;
}

.viewUploadedImages img {
  height: 80px;
}

.br-12pxi {
  border-radius: 12px !important
}

.crossBtnImg {
  width: 25px;
  height: 25px;
  object-fit: contain;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  right: 0px;
  z-index: 9;
  background-color: #eaeeff;
  color: #667DD1;
}

.crossBtnImg span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-top: -3px;
}


#AllSvgDislayUnset {
  display: unset !important;
}

.smsstrokestyle {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;

}

.emiliconstroke {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.phonesinputstyle {
  border-radius: 4px;
  padding: 0.5rem 12px;
  border: 1px solid #f5f5f5 !important;
  box-shadow: none !important;
  outline: none !important;
  color: #70747b !important;
  font-size: 0.8rem;

}

.PhoneInput--focus {
  border: 1px solid #667DD1 !important;
}


.phonesinputstyle:focus,
.phonesinputstyle:active {
  border: 1px solid #667DD1 !important;
  box-shadow: none !important;
  outline: none !important;
  color: #70747b !important;
  font-size: 0.8rem;

}

.PhoneInputInput {
  /* background-color: #f5f5f5; */
  background-color: #fff;
  color: #70747b !important;
  font-size: 0.8rem !important;
}

.adclosing .PhoneInputInput {
  background-color: #ffff !important;
  color: #70747b !important;
  font-size: 0.8rem !important;
}

.addlistingphoneinput .PhoneInputInput {
  background-color: #ffff !important;
  color: #70747b !important;
  font-size: 0.8rem !important;
}

.pl1rem {
  padding-left: 1rem !important;
}

.w80imp {
  width: 80% !important;
}

.css-8ndp5h {
  border-radius: 0px;
}

.PhoneInputInput:focus-visible {

  border: unset !important;
  box-shadow: none !important;
  outline: none !important;
  color: #70747b !important;
  font-size: 0.8rem;
}

.PhoneInput {
  background-color: #f5f5f5;
}

.pricerangeslider {
  width: 100%;
  height: 2px;
  background-color: #e6e6e9;
  margin-top: 4px;
}

.pricerangeslider .thumb,
.pricerangeslider .thumb:focus-visible,
.pricerangeslider .thumb.active {
  width: 12px;
  height: 12px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #667DD1 !important;
  top: -6px;
  padding: 0 !important;
  outline: none !important;
  z-index: unset !important;
}


.pricerangeslider .example-track {
  top: 0;
  height: 2px;
}

.example-track.example-track-1 {
  background: #667DD1;
}

.example-track.example-track-2 {
  background: #e6e6e9;
}

.forsuggestbeds {
  width: 200px;
}

.activeimgpassword {
  height: 14px;
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
}

.CustomSelectStyles :hover .PrefixClassSelect__placeholder,
.CustomSelectStyles :hover .PrefixClassSelect__input-container {
  color: #fff !important;
}

/* .CustomSelectStyles :hover .participant_defineclass {
  color: #fff !important;
} */



.w_150pximp {
  width: 150px !important;
}

.CustomSelectStyleofselectinmaster :hover .PrefixClassSelect__placeholder,
.CustomSelectStyleofselectinmaster :hover .PrefixClassSelect__input-container {
  color: #fff !important;
}

/* email template create start  */
.email-editor-container {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
}

.createEmailHeading h1 {
  text-align: center;
  margin-bottom: 20px;
}

.email-editor-wrapper {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.emailTemplateButtons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.emailTemplateButtons button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
}

.emailTemplateButtons button:hover {
  background-color: #0056b3;
}

/* email template create end */

.childtaskModalBodyMain {
  padding: 1rem 1.5rem;
}

.width350pximp {
  width: 350px !important;
}

.removeLabelStyle {
  -webkit-appearance: unset !important;
  background-color: unset !important;
  border: none !important;
  box-shadow: unset !important;
  padding-left: 0.5rem !important;
  border-radius: unset !important;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  color: #70747b !important;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 5px;
}

.isShowChildDeleteCrossMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f7fb;
  padding: 1.2rem;
}

.isShowChildDeleteCrossMain h3 {
  font-size: 1rem;
  color: #131523;
  font-weight: 500;
}

.swiper-button-next,
.swiper-button-prev {
  color: #D5D7E3 !important;
}

/* html email builder start */
.blockbuilder-branding {
  display: none !important;
}

.blockbuilder-branding {
  display: none !important;
}

.dspXZR .blockbuilder-branding {
  display: none !important;
}

.templateEditor {
  display: none !important;



}


.w70pimp {
  width: 70% !important;
}

/* html email builder end */

.emailLowerCase {
  text-transform: lowercase !important;
}

.maxHeightSummarySeq {
  max-height: 70vh;
}

.exteranal_time_data_desing {
  color: #131523 !important;
  font-size: 0.9rem !important;
}



/* sonal testing file  */
.cardDatahhh h3 {

  color: #131523 !important;
  font-size: 1rem !important;
}

.mr_m_12imp {
  margin-right: -0.2rem !important;

}

.buttonaddtitle {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.todolist {
  margin-right: 2px;
  background-color: #D5D7E3;

  font-size: 12px;
}

.todolist::placeholder {
  color: #131523;
}

/* sonal testing file  */

.noInternetMain {
  height: calc(100vh - 163px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noInternetImg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.noInternetImg img {
  max-height: 250px;
}

.PhoneInputCountryIconUnicode {
  font-size: 1rem;
}

/* .DisplayNone{
  display: none;
  visibility: hidden;
} */

.calanderFix_Width {
  width: calc(100% - 600px);
  animation: right-left ease 0.3s;
}

@keyframes right-left {
  0% {
    transform: translateX(700px);
  }

  100% {
    transform: translateX(0px);
  }
}

@media screen and (min-width: 1450px) {
  .widthmangaerimportcontact {
    width: 25%;
  }
}


@media screen and (min-width: 1450px) and (max-width: 1500px) {
  .widthadjuster {
    width: 114% !important;
  }

  .widthmangaerimportcontact {
    width: 25%;
  }
}

@media screen and (max-width: 1400px) {
  .calanderFix_Width {
    width: calc(100% - 300px);
  }
}

/* .widthmangaerimportcontact {
  width: 35%;
} */

.sizeThumbnailText {
  color: #131523;
  font-size: 14px;
}

.paddingZero {
  padding-bottom: 0 !important;
}

.servicesimg img {
  height: 50px;
}

.straight_border {
  border-radius: 0px !important;
}

.react-tel-input .form-control {
  background-color: #ffff !important;
  border: 1px solid #fff !important;
  border-radius: 4px !important;
  padding: 0.5rem 12px !important;
  width: 85% !important;
  color: #70747b !important;
  margin-left: 15% !important;
  height: 12px !important;
  font-family: "GillSansMedium" !important;
  font-size: 0.9rem !important;
}


.announcementdescrioption {
  max-height: "25vh" !important;
  overflow-y: auto;
  color: #70747b !important;
  min-height: 12vh;
  max-height: 12vh;
  font-size: 0.9rem !important;
  padding-bottom: 0.5rem !important;
  overflow-x: hidden !important;
}

.react-tel-input .form-control:focus,
.react-tel-input .form-control:active {
  border: 1px solid #667DD1;
  box-shadow: none !important;
  outline: none !important;
}

.overFlow80vh {
  margin-top: 15%;
  margin-left: 15%;
  /* max-height: 80vh; */
  overflow: auto;
  /* max-width: 100%; */
}

.overFlow80vh1 {
  max-height: 65vh;
  overflow: scroll !important;
  /* max-width: 100%; */
}


.EmailTemplatesAccordion .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(180deg) !important;
  height: 50px;
}

.moreThanOneText {
  color: #667DD1 !important;
  padding: 0 !important;
}

.width80 {
  width: 80%;
}

.width80imp {
  width: 80% !important;

}

.meetingbgcolor {
  border: 1px solid #81d5dd;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* transition CSS */

.none-transition {
  transition: width 2s;
}

.contactpreviewphoneinput .react-tel-input .selected-flag {
  display: none !important;
  border: none !important;
  visibility: hidden !important;

}

.contactpreviewphoneinput .react-tel-input .form-control {
  margin-left: 0% !important;
  background-color: #f5f7fb !important;
  border: 1px solid #f5f7fb !important;
  width: 102% !important;
}

.contactpreviewphoneinput .react-tel-input {
  width: 100% !important;
}

.contactpreviewphoneinput .react-tel-input .flag-dropdown {
  border: none !important;
}

.textnormal {
  color: #70747b !important;
}

.w-206pxi {

  width: 206px !important;

}

.editIconMain h4 {
  width: 100%;
  font-size: 0.9rem;
  color: #131523;
  margin-bottom: 0;
  margin-left: 8px;
  text-transform: capitalize;
}

.emailpreviewsimpleaccordian .css-i4bv87-MuiSvgIcon-root {
  margin-top: 0px !important;
}

.css-i8li4u-Input {
  min-height: 33px !important;
}

.doughtnuttextcenter {
  margin-left: 41% !important;
  /* margin-bottom: 10%; */
  margin-top: -39% !important;
}

.l-3_imp {
  left: 3px !important;
}

.css-mohuvp-dummyInput-DummyInput {
  min-height: 37px !important;
}

.selectbuttonmastertab .css-1h0yxl0-singleValue {
  color: black !important;
}

.transition_05ease {
  transition: 0.5s ease;
}


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #70747b !important;
}


.css-1g1xewz-option:hover {
  background-color: #6A7ABC !important;
}

.css-dkqepo {
  height: 49vh !important;
}

.shadow_design {
  padding: 0.3rem;
  border: 1px solid #e6e6e9;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left54imp {
  left: 54px !important;
}

.minw50pimp {
  min-width: 200px !important;
}

.emailmettingbodyareanew .rdw-editor-toolbar {
  background-color: #f5f5f5;
}

.emailmettingbodyareanew .rdw-image-wrapper,
.rdw-link-wrapper {
  display: none !important;
}

.emailmettingbodyareanew .rdw-editor-toolbar {
  display: flex;
  justify-content: flex-end;
  background: none !important;
}

.emailmettingbodyareanew .rdw-editor-main {
  height: calc(100vh - (36vh + 197.36px)) !important;
}


.newsmsbodyareanew .rdw-editor-toolbar {
  background-color: #f5f5f5;
}

.newsmsbodyareanew .rdw-image-wrapper,
.rdw-link-wrapper {
  display: none !important;
}

.newsmsbodyareanew .rdw-editor-toolbar {
  display: flex;
  justify-content: flex-end;
  background: none !important;
}

.newsmsbodyareanew .rdw-editor-main {
  height: 22vh;
}

.css-jtk1p6-option:hover {
  background-color: #667DD1 !important;
}

.transform_14degimp {
  transform: rotate(30deg) !important;
  transform-origin: center center;
  /* Set the rotation point to the center of the element */
  transition: transform 0.5s ease-in-out;
}

.left1pximp {
  left: 1px !important;
}

.visibilityhiddendisplaynone {
  visibility: hidden !important;
  display: none;
}

.min-max-w_189 {
  /* min-width: 189px !important;
  max-width: 189px !important; */
  min-width: 100% !important;
  max-width: 100% !important;
}

.min-max-wh_182 {
  /* min-width: 182px !important;
  max-width: 182px !important;
  min-height: 182px !important;
  max-height: 182px !important; */
  min-width: 100% !important;
  max-width: 100% !important;
  min-height: 183px !important;
  max-height: 183px !important;
}

.min-max-wh_180 {
  /* min-width: 174px !important;
  max-width: 174px !important;
  min-height: 174px !important;
  max-height: 174px !important; */
  min-width: 100% !important;
  max-width: 100% !important;
  min-height: 174px !important;
  max-height: 174px !important;
}

.width-100p-imp {
  width: 100% !important;
}

.con-tooltip {

  /* position: relative;
  background: #F2D1C9;

  border-radius: 9px;
  padding: 0 20px;
  margin: 10px;

  display: inline-block;

  transition: all 0.3s ease-in-out;
  cursor: default; */

}

/*tooltip */
.tooltip {
  visibility: hidden;
  z-index: 1;
  opacity: .40;

  width: 200px;
  padding: 0px 20px;

  background: #667DD1;
  color: #fff;

  position: absolute;
  top: -140%;
  left: -25%;


  border-radius: 9px;
  font: 16px;

  transform: translateY(9px);
  transition: all 0.3s ease-in-out;

  box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
}


/* tooltip  after*/
.tooltip::after {
  content: " ";
  width: 0;
  height: 0;

  border-style: solid;
  border-width: 12px 12.5px 0 12.5px;
  border-color: #333 transparent transparent transparent;

  position: absolute;
  left: 40%;

}

.con-tooltip:hover .tooltip {
  visibility: visible;
  transform: translateY(-10px);
  opacity: 1;
  transition: .3s linear;
  animation: odsoky 1s ease-in-out infinite alternate;

}

@keyframes odsoky {
  0% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(1px);
  }

}

/*hover ToolTip*/
.left:hover {
  transform: translateX(-6px);
}

.top:hover {
  transform: translateY(-6px);
}

.bottom:hover {
  transform: translateY(6px);
}

.right:hover {
  transform: translateX(6px);
}



/*left*/

.left .tooltip {
  top: -20%;
  left: -170%;
}

.left .tooltip::after {
  top: 40%;
  left: 90%;
  transform: rotate(-90deg);
}




/*bottom*/

.bottom .tooltip {
  top: 115%;
  left: -20%;
}

.bottom .tooltip::after {
  top: -17%;
  left: 40%;
  transform: rotate(180deg);
}



/*right*/

.right .tooltip {
  top: -20%;
  left: 50px;
}

.right .tooltip::after {
  top: 40%;
  left: -12%;
  transform: rotate(90deg);
}


.circledesignoverall {
  border-radius: 50% !important;
  background-color: #667DD1;
  /* margin-left: 7% !important; */
  color: white;
}

/* .circledesignoverall .css-tzssek-MuiSvgIcon-root {
  width: 0.8em;
  height: 0.8em;
} */

.font_size_12remimp {
  font-size: 1.2rem !important;
}


.font_size_9remimp {
  font-size: 0.9rem !important;
}

.transition05so {
  transition: transform 0.2s ease-in-out !important;
}


.font_size_16pximp {
  font-size: 16px !important;
}

.responsivekeyboardArrowRightIcon {
  width: 0.8em !important;
  height: 0.8em !important;
}

.left_38pximp {
  left: 38px !important;
}

.bg_commonimp {
  background-color: #667DD1 !important;
}

.width70imp {
  width: 70% !important;
}

.transition_ease_out {
  transition: opacity 0.5s ease-in-out !important;
}

.text_transform_none_imp {
  text-transform: none !important;
}

.padding_top_0px_imp {
  padding-top: 0px !important;
}

.padding_bottom_0px_imp {
  padding-bottom: 0px !important;
}

.margin_bottom_0px_imp {
  margin-bottom: 0px !important;
}


.tab_design_brochure_template {
  font-family: "GillSansMedium";
  font-weight: 500;
  border-radius: 50px;
  background-color: #fff;
  color: #70747b;
  border: 1px solid #667DD1;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 0.6rem;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.newsmstemplate2ndFOrCal {
  padding: 1.5rem;
  height: calc(100vh - (22vh + 136.38px));
  overflow: auto;
  padding-top: 0rem !important;
}


.padding_right_left_opx_imp {
  padding-left: 0px !important;
  padding-right: 0px !important;
}


.bottom_24px_imp {
  bottom: 24px !important;
}

.wh_40p {
  width: 40px !important;
  height: 40px !important;
}


.logineyedesign {
  height: 0.7em !important;
  width: 0.7em !important;
  margin-top: 0px !important;
}


.inlargeddatapicged .react-datepicker-wrapper {
  width: 100%;
}


.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex !important;
  align-items: center !important;
}

.mtl_none_imp {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.bgwhiteimpt {
  background-color: white !important;
}

.rbc-event-content {
  font-size: 100% !important;
}

.rbc-event-label {
  font-size: 90% !important;
}

.celebration-banner {
  /* Your regular banner styles here */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 15px;
  z-index: 9999;
  animation: celebration 2s infinite;
  opacity: 0;
  /* Initial opacity */
  transition: opacity 0.5s ease;
  /* Transition for opacity */
}

.celebration-banner.show {
  opacity: 1;
  /* Make the banner visible */
}

.cardmainbox {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  cursor: pointer !important;
  border: 1px solid #e6e6e9;
  border-radius: 10px;
  padding: 1rem;
}

.cardmainboxwithouthover {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  cursor: pointer !important;
  border: 1px solid #e6e6e9;
  border-radius: 10px;
  padding: 1rem;
}

.logmeetingheaderdesing {
  width: 100%;
  font-family: "GillSansMedium";
  border: 1px solid #70747b;
  border-radius: 14px;

  padding-left: 1rem !important;
  padding-right: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px !important;
  margin-top: 18px;
  font-size: 18px;
}

.buyershowingbgdesing {
  background-color: #ffedde;
  border: 1px solid #ffedde !important;
  color: #131523 !important;
}

.litingshowingbgdesing {
  background-color: #E7EBFB;
  color: #131523 !important;
  border: 1px solid #E7EBFB !important;

}

.meetingbddesing {
  background-color: #EBF8FA;
  color: #131523 !important;
  border: 1px solid #EBF8FA !important;
}

.cmabgdesing {
  background-color: #d3ffe9ab;
  color: #131523 !important;
  border: 1px solid #d3ffe9ab !important;
}

.othersbgdesign {
  background-color: #e6e6e9;
  color: #131523 !important;
  border: 1px solid #e6e6e9 !important;
}

.logtaskheaderdesign {
  font-size: 1.2rem;
  color: #131523;
  font-weight: 500;
  margin-top: 1px;
}

.PhoneRadioBglogtask {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.lagcalldesign {
  border: 2px solid #EF6C00;
}

.logemailbgdesing {
  border: 2px solid #E46776;

}

.logemaitododesing {
  border: 2px solid #92a9f7;

}

.logemaitsmsdesgin {
  border: 2px solid #95cead;

}

.from_design_template {
  text-transform: uppercase;
  font-family: "GillSansMedium";
  border-radius: 0px;
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important;
  padding: 4px;
  color: #667DD1;
  font-weight: 400;
  transition: font-weight 0.3s ease-in-out;
  font-size: 0.7rem;
  background-color: #e7edfa;
  border: 1px solid #f5f7fb;
}

.to_design_template {
  text-transform: uppercase;
  font-family: "GillSansMedium";
  border-radius: 15px;
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important;
  padding: 4px;
  color: #ffff;
  font-family: GillSansMedium;
  font-size: 0.7rem;
  /* color: #667DD1; */
  font-weight: 400;
  transition: font-weight 0.3s ease-in-out;
  background-color: #667DD1;
  border: 1px solid #667DD1;
}

.rbc-day-slot .rbc-events-container {
  margin-right: none !important;
  border-left: 1px solid #d5d7e3 !important;
}

.cardmainbox:hover {
  box-shadow: 0px 3px 6px #00000029;
}

.min_w_custom_calendar {
  min-width: 220px;
}

.emailbuttonwithourborder {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-radius: 5px;
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: #667DD1;
  font-family: "GillSansMedium";
}

.calendarwidhtdesing {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
}

.w_90imp {
  width: 90% !important;
}


@media screen and (max-width: 1855px) {
  .calendarwidhtdesing {
    max-width: 20ch;

  }
}

@media screen and (max-width: 1750px) {
  .min_w_custom_calendar {
    min-width: 165px;
  }

  .calendarwidhtdesing {
    max-width: 20ch;

  }
}

@media screen and (max-width: 1550px) {
  .calendarwidhtdesing {
    max-width: 17ch;

  }
}


@media screen and (max-width: 1500px) and (min-width: 990px) {
  .table-theme-1 td {
    padding-left: 1rem;
  }

  .table-theme-1 th {
    padding-left: 1rem;
  }

  .heightdefined {
    height: 45vh;
  }

  .header {
    padding: 13px 40px;
  }

  .mainfilter {
    margin-bottom: 0.5rem;
  }


  .gapp2viewport {
    gap: 10px;
  }

  .subfilter {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 1720px) and (min-width: 1150px) {
  .filterinputscontent {
    width: 65%;
  }
}


@media screen and (max-width: 1250px) and (min-width: 1200px) {
  .react-datepicker__input-container {
    /* width: 80% !important; */
  }
}

@media screen and (max-width: 1500px) {
  .min_w_custom_calendar {
    min-width: 165px;
  }

  .calendarwidhtdesing {
    max-width: 17ch;

  }
}

@media screen and (max-width: 1450px) {
  .min_w_custom_calendar {
    min-width: 155px;
  }

  .page-container {
    width: calc(100% - 220px);
    height: 100vh;
    overflow-y: scroll;
  }

  .side-nav {
    width: 220px;
  }

  .calendarwidhtdesing {
    max-width: 12ch;

  }
}

@media screen and (max-width: 1380px) {
  .min_w_custom_calendar {
    min-width: 145px;
  }

  .widthmangaerimportcontact {
    width: 45%;
  }
}

@media screen and (max-width: 1360px) {
  .min_w_custom_calendar {
    min-width: 140px;
  }

  .calendarwidhtdesing {
    max-width: 10ch;

  }
}

@media screen and (max-width: 1300px) {
  .min_w_custom_calendar {
    min-width: 130px;
  }

  .calendarwidhtdesing {
    max-width: 9ch;

  }
}


@media screen and (max-width: 1260px) {
  .CustomSelectStyle {

    width: 190px;
  }
}

@media screen and (max-width: 1250px) {
  .min_w_custom_calendar {
    min-width: 120px;
  }

  .CustomSelectStyle {

    width: 160px;
  }

  .side-nav {
    width: 220px;
  }
}

@media screen and (max-width: 1200px) {
  .min_w_custom_calendar {
    min-width: 110px;
  }

  .calendarwidhtdesing {
    max-width: 8ch;

  }

  .widthmangaerimportcontact {
    width: 46%;
  }
}

@media screen and (max-width: 1150px) {
  .min_w_custom_calendar {
    min-width: 100px;
  }

  .calendarwidhtdesing {
    max-width: 7ch;

  }

  .widthmangaerimportcontact {
    width: 50%;
  }
}

@media screen and (max-width: 1050px) {
  .min_w_custom_calendar {
    min-width: 90px;
  }

  .calendarwidhtdesing {
    max-width: 6ch;

  }
}


@media screen and (max-width: 790px) {
  .TransactionImgSliderBg {
    width: 100%;
    height: 60vw;

  }
}

@media screen and (max-width: 700px) {
  .min_w_custom_calendar {
    min-width: 60px;
  }

  .widthmangaerimportcontact {
    width: 100%;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1550px) {
  .filterinputstasks {
    width: 100% !important;
  }
}

@media screen and (max-width: 1100px) and (min-width: 700px) {
  /* .min_w_custom_calendar {
    min-width: 30px;
  } */

  .width350pximp {
    width: 220px !important;
  }
}

@media screen and (max-width: 550px) {
  .min_w_custom_calendar {
    min-width: 30px;
  }

  .width350pximp {
    width: 240px !important;
  }
}

@media screen and (min-width: 521px) {
  .menu_bar_drop_down_desing_displaydesing {
    display: flex;
  }

  .menu_bar_uppper_div_desing {
    text-align: left;
    left: 107px;
  }

}

@media screen and (max-width: 521px) {
  .icons_menu_icon {
    background-color: #fff !important;
  }

  .menu_bar_drop_down_desing {
    min-width: none;
  }

}

@media screen and (max-width: 420px) {

  .width350pximp {
    width: 211px !important;
  }
}