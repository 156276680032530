.maindiv {
  /* background-image: url("../images/login/bglogin.jpg"); */
  background-image: url('  https://d2j5nfkeiawv5j.cloudfront.net/bglogin.jpg');
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 3%;

}

.forms {
  box-shadow: 0px 3px 6px #27272729;
  border-radius: 20px;
  background-color: #ffff;
  height: auto;
  width: 25%;
  /* position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(0%, -50%); */
}

.formmainsub {
  padding: 2.5rem;
}

.loginsubcontent h3 {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #6A7ABC;
}

.loginsubcontent img {
  height: 3rem;
}

.loginsubcontent h4 {
  padding-bottom: 1.5rem;
  font-size: 1.1rem;
  color: #131523;
}

.starcolorstyle {
  color: #f0142f;
}

.usenameemail {
  border: 1px solid #e6e6e9;
  width: 100%;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "GillSansMedium" !important;
  padding: 0.6rem 1rem;
}

.usenameemail:focus,
.usenameemail:active {
  outline: none !important;
  border: 1px solid #e6e6e9;
  box-shadow: none !important;
}

.formstyleemail h3 {
  padding-bottom: 5px;
  font-size: 0.9rem;
  color: #131523;
}

.passwordloginstyle {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.imgpassword {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
}

.passwordlogin {
  padding-top: 1rem;
}

.bgcolorbutton {
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  text-align: center !important;
  font-size: 1.2rem !important;
  font-family: "GillSansMedium" !important;
  color: #70747b !important;
  background-color: #e6e6e9 !important;
  padding: 5px 0px !important;
}

.bgcolorbuttons {

  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  text-align: center !important;
  font-size: 1.2rem !important;
  font-family: "GillSansMedium" !important;
  color: #ffff !important;
  background-color: #667DD1 !important;
  padding: 5px 0px !important;
}

.bgcolorbuttons {


  background-color: #8796d2 !important;

}

.formcontent {
  cursor: pointer;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  color: #667DD1;
  font-family: "GillSansMedium";
  font-size: 1rem;
}

.usenameemail::placeholder {
  font-size: 1rem;
  color: #70747b;
  font-family: "GillSansMedium" !important;
}

.forgetpasswordnutton {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forgetpasswordnutton h3 {
  font-size: 1rem;
  color: #667DD1;
  /* border-bottom: 0.5px solid #667DD1; */
}

/* media query start */
@media screen and (max-width: 1500px) {
  .forms{
    width: 31%;
  }
}
@media screen and (max-width: 1200px) {
  .forms {
    width: 35%;
  }

  .loginsubcontent h3 {

    font-size: 1rem;

  }

  .loginsubcontent h4 {

    font-size: 1rem;

  }
}

@media screen and (max-width: 991px) {
  .forms {
    width: 40%;
  }




}

@media screen and (max-width: 768px) {
  .forms {
    width: 50%;
    margin: auto;
  }




}

@media screen and (max-width: 599px) {
  .forms {
    width: 65%;
  }




}

@media screen and (max-width: 414px) {
  .forgetpasswordnutton h3 {
    font-size: 0.8rem;


  }

  .forms {
    width: 90%;
  }




}

@media screen and (max-width: 375px) {
  .forms {
    width: 90%;
    margin: auto;
  }




}


/* media query end */